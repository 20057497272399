/* ========================================
   Owl Carousel CSS File
   ========================================*/
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  padding: 0 15px;
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: inline-block;
  /*width: 100%;*/
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* 
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* owl dot */
.owl-theme .owl-dots {
  display: none;
  text-align: left;
  padding-left: 4px;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 4px 1px 10px 6px;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
}

/* owl Next and Prev buttons */
.owl-theme .owl-controls .owl-nav div {
  background: #ff5e14;
  text-align: center;
  display: block;
  outline: 0;
  width: 34px;
  text-align: center;
  line-height: 34px;
  height: 34px;
  color: #fff;
  top: 50%;
  font-size: 0;
  margin-top: -20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.owl-theme .owl-controls .owl-nav div:hover {
  background: #333;
}
.owl-theme .owl-controls .owl-nav div.owl-prev {
  position: absolute;
  top: 165px;
  right: 59px;
  display: block;
}
.owl-theme .owl-controls .owl-nav div.owl-prev:before,
.owl-theme .owl-controls .owl-nav div.owl-next:before {
  font-family: FontAwesome;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.owl-theme .owl-controls .owl-nav div.owl-prev:before {
  content: '\f104';
}
.owl-theme .owl-controls .owl-nav div.owl-prev:hover:before,
.owl-theme .owl-controls .owl-nav div.owl-next:hover:before {
  color: #fff;
}
.owl-theme .owl-controls .owl-nav div.owl-next {
  position: absolute;
  top: 165px;
  right: 20px;
  display: block;
}
.owl-theme .owl-controls .owl-nav div.owl-next:before {
  content: '\f105';
}

.ttm-boxes-spacing-5px.owl-carousel .owl-item {
  padding-right: 2.5px;
  padding-left: 2.5px;
  margin-bottom: 5px;
}

/* testimonial-slide */
.testimonial-slide.owl-theme .owl-controls .owl-dots {
  position: absolute;
  right: 60px;
  top: 40%;
}
.testimonial-slide.owl-theme .owl-controls .owl-dots .owl-dot {
  display: block;
}
.testimonial-slide.owl-theme .owl-controls .owl-dots .owl-dot.active span {
  background-color: #fff;
}

/* portfolio-slide */
.portfolio-img-slide.owl-carousel .owl-item {
  padding: 0;
}
.portfolio-img-slide.owl-carousel.owl-theme .owl-nav div,
.portfolio-img-slide.owl-carousel.owl-theme .owl-nav:hover div {
  background-color: transparent;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.portfolio-img-slide.owl-theme .owl-controls .owl-nav div.owl-prev:before,
.portfolio-img-slide.owl-theme .owl-controls .owl-nav div.owl-next:before {
  color: #182333;
}
.portfolio-img-slide.owl-theme .owl-controls .owl-nav div.owl-prev {
  right: auto;
}
.portfolio-img-slide.owl-theme .owl-controls .owl-nav div.owl-next {
  right: 0;
}

.portfolio-img-slide {
  position: relative;
}
.portfolio-img-slide.owl-carousel .owl-item {
  padding: 0;
}
.portfolio-img-slide.owl-carousel.owl-theme .owl-controls .owl-dots {
  width: 100%;
  position: absolute;
  bottom: 15px;
  text-align: center;
}
.portfolio-img-slide.owl-carousel.owl-theme .owl-dots {
  display: none;
  text-align: left;
  padding-top: 0px;
  padding-left: 4px;
  margin-top: -20px;
}
.portfolio-img-slide.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  background-color: #fff;
  border-color: #fff;
}
/* services-slide */
.services-slide.owl-theme .owl-controls .owl-nav div {
  color: #0b0c26;
  z-index: 1;
  background: transparent;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 2px solid #f1f1f1;
  border-radius: 50%;
  text-align: center;
}
.services-slide.owl-theme .owl-controls .owl-nav div.owl-prev:before,
.services-slide.owl-theme .owl-controls .owl-nav div.owl-next:before {
  color: #828c96;
  font-size: 26px;
  font-weight: normal;
}
.services-slide.owl-theme .owl-controls .owl-nav div.owl-prev {
  top: 50%;
  left: -60px;
  margin-top: -47px;
}
.services-slide.owl-theme .owl-controls .owl-nav div.owl-next {
  top: 50%;
  right: -60px;
  margin-top: -47px;
}
@media (max-width: 1299px) {
  .services-slide.owl-theme .owl-controls .owl-nav div.owl-next {
    display: none !important;
  }
  .services-slide.owl-theme .owl-controls .owl-nav div.owl-prev {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .services-slide.owl-theme .owl-controls .owl-nav div.owl-next {
    top: 47%;
    right: 0;
  }
  .services-slide.owl-theme .owl-controls .owl-nav div.owl-prev {
    top: 47%;
    left: 0;
  }
}
@media (max-width: 991px) {
  .testimonial-slide.owl-theme .owl-controls .owl-dots {
    right: 15px;
  }
}
@media (max-width: 768px) {
  .owl-theme .owl-controls .owl-nav div.owl-prev {
    display: none !important;
  }
  .owl-theme .owl-controls .owl-nav div.owl-next {
    display: none !important;
  }
}
