.locationItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  color: #5b6670;
  font-size: 18px;
}

.locationItem__Content {
  margin-top: 1.5rem;
}

.locationItem__Details {
  flex: 1;
}

.locationItem__Map {
  flex: 1;
}

@media screen and (min-width: 900px) {
  .locationItem {
    flex-direction: row;
    column-gap: 2rem;
  }
}
