.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.button {
  align-self: flex-start;
}

.captcha {
  align-self: flex-end;
  flex: 1;
  justify-content: flex-end;
  display: flex;
}
