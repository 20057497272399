.Toastify__toast--success {
  background-color: var(--success)
}

.Toastify__toast--error {
  background-color: var(--danger)
}

.Toastify__toast--warning {
  background-color: var(--warning)
}

.Toastify__toast--info {
  background-color: var(--info)
}
