.pageContainer {
  display: flex;
  flex-direction: column;
}

.pageContainer__Content {
  margin-top: 2rem;
  flex: 1;
}

.pageContainer__Content {
  flex: 1;
  order: 2;
}

.pageContainer__Image {
  flex: 1;
  order: 1;
}

@media screen and (min-width: 768px) {
  .pageContainer {
    flex-direction: row;
    column-gap: 2rem;
  }
  .pageContainer__Content {
    order: 1;
  }

  .pageContainer__Image {
    order: 2;
  }
}
