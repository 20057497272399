@import 'static/css/bootstrap.min.css';
@import 'static/css/animate.css';
@import 'static/css/shortcodes.css';
@import 'static/css/main.css';
@import 'static/css/toast.css';
@import 'static/css/responsive.css';

@import 'reactjs-popup/dist/index.css';

a:focus-visible,
button:focus-visible {
  outline: 1px solid #25c9d1 !important;
}

#totop {
  display: block;
}

.owl-nav.disabled {
  display: none;
}

.required:after {
  content: ' *';
  color: red;
}

.text-cyan {
  color: #25c9d1;
}

.big-checkbox {
  transform: scale(1.5);
}

.custom-dropdown-button.dropdown-menu.show {
  line-height: 22px;
  font-size: 0.85rem;
}

.custom-dropdown-button.dropdown-menu.dropdown-position.show {
  top: 66%;
  left: 11%;
}

.btn-group.custom-link-dropdown > a {
  cursor: pointer;
}

.main-content-editor ul {
  list-style-type: disc;
}

.ReactModal__Body--open .page,
.ReactModal__Body--open body .site-main,
.ReactModal__Body--open .ttm-page-title-row-bascule {
  z-index: unset;
}

.popup-content {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  max-width: 760px;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .btn-group.custom-dropdown-button {
    margin-right: 8px;
  }

  .btn-group.custom-link-dropdown {
    vertical-align: unset;
  }

  .btn-group.custom-link-dropdown > a {
    padding: 0 6px 0 17px !important;
  }
}

@media only screen and (max-width: 1420px) {
  .custom-tp-text {
    font-size: 4vw !important;
  }
}

@media (max-width: 1200px) {
  .fixMobile {
    padding-top: 100px !important;
  }
}
