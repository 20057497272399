.spacing {
  margin-top: 2rem;
}

.tableNoBorder td {
  border-top: none;
}

.history {
 font-size: 18px;
 font-weight: bold;
}

.selectTd {
  font-weight: bold;
  color: #721c24;
}
