.spacing {
  margin-top: 2.5rem;
}

.paragraph {
  font-size: 24px;
  line-height: 1.4em;
}

.list {
  list-style-type: disc;
}

.procedures__title {
  margin-bottom: 1px;
  color: #211261;
}

.procedures__listTitle {
  color: #211261;
  font-weight: bold;
}

.procedures__paragraph {
  font-size: 20px;
}

.important {
  color: white;
  background-color: rgba(91, 102, 112, 0.8);
  padding: 1rem;
}

.important__title {
  color: #25c9d1;
  font-weight: bold;
}

.claimsForm {
  display: flex;
  margin-top: 1.5rem;
  padding: 1.5rem;
}
