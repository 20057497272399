/** 
      1. ttm-row 

      2. Row-Equal-Height

      3. Bg-Layer 

      4. Row-Bg-Image

      5. Col-Bg-Image

      6. highlight-text

      7. Section-Title 

      8. Separator

      9. Buttons

      10. Icons

      11. Fid

      12. featured-icon-box

      13. Featured-imagebox

      14. Processbox

      15. Progress-Bar 

      16. Testimonial

      17. Client-row

      18. Accordion

      19. Wrap-Form

      20. Tab

      21. Boxes-Spacing

      22. Pricing-Plan

      23. Sidebar

      24. products
      
      25. panel

**/

/* ===============================================
    1.ttm-row
------------------------*/

.ttm-row {
  padding: 90px 0;
}
.ttm-row.broken-section,
.ttm-row.testimonial-section,
.ttm-row.about2-section,
.ttm-row.row-top-section {
  padding: 0 0;
}
.ttm-row.project-style2-section,
.ttm-row.single-project-section {
  padding-bottom: 60px;
}
.ttm-row.pricing-plan-section,
.ttm-row.team-member-section,
.ttm-row.faq-section,
.ttm-row.about-blog-section {
  padding-bottom: 70px;
}
.ttm-row.blog-grid-section,
.ttm-row.blog-left-img-section,
.ttm-row.services2-section {
  padding-bottom: 80px;
}
.ttm-row.services-section {
  padding-bottom: 130px;
}
.ttm-row.row-title-section {
  padding-top: 220px;
  padding-bottom: 110px;
}
.ttm-row.blog-text-section {
  padding-top: 207px;
  padding-bottom: 180px;
}
.ttm-row.blog-section,
.ttm-row.client-section,
.ttm-row.blog2-section,
.ttm-row.about-blog-section,
.ttm-row.contact-form-section {
  padding-top: 0;
}
.ttm-row.strategy-section,
.ttm-row.chart-section,
.ttm-row.blog-section,
.ttm-row.about-blog2-section,
.ttm-row.map-section {
  padding-bottom: 0;
}
.ttm-row.blog-section,
.ttm-row.about-blog-section {
  margin-top: -180px;
}
.ttm-row.client-section {
  padding-bottom: 37px;
}
.ttm-row.about-section,
.ttm-row.process-section {
  padding-bottom: 100px;
}
.ttm-row.strategy-section {
  padding-top: 140px;
}
.ttm-row.team-work-section {
  padding-bottom: 60px;
}
.ttm-row.blog-text2-section {
  padding-top: 250px;
  padding-bottom: 250px;
}
.ttm-row.row-title2-section {
  padding-bottom: 200px;
}
.ttm-row.row-title2-section,
.ttm-row.tab-section {
  padding-top: 150px;
}
.ttm-row.about-top-section {
  padding-top: 110px;
}
.ttm-row.about-services-section {
  padding-bottom: 50px;
}
.ttm-row.row-title3-section {
  padding-top: 157px;
  padding-bottom: 117px;
}

/* ===============================================
    2.Row-Equal-Height
------------------------*/

.row-equal-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.row-equal-height > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* ===============================================
    3.Bg-Layer 
------------------------*/

.ttm-bg {
  position: relative;
}
.ttm-col-bgimage-yes,
.bg-layer-equal-height .ttm-col-bgcolor-yes {
  height: 100%;
  z-index: 9;
}
.ttm-bg-layer,
.ttm-titlebar-wrapper .ttm-titlebar-wrapper-bg-layer,
.ttm-bg-layer .ttm-bg-layer-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.ttm-left-span .ttm-col-wrapper-bg-layer {
  width: auto;
  margin-left: -100px;
  right: 0;
}
.ttm-right-span .ttm-col-wrapper-bg-layer {
  width: auto;
  margin-right: -100px;
  right: 0;
}
.ttm-bg.ttm-bgimage-yes > .ttm-bg-layer,
.ttm-bg.ttm-col-bgimage-yes .ttm-bg-layer-inner {
  opacity: 0.87;
}

.ttm-bgcolor-skincolor.map-bgimg.ttm-bg.ttm-bgimage-yes > .ttm-bg-layer,
.ttm-bgcolor-skincolor.map-bgimg.ttm-bg .ttm-bg-layer .ttm-bg-layer-inner {
  opacity: 0.2;
}
.layer-content {
  position: relative;
  z-index: 9;
  height: 100%;
}

.bg-layer > .container > .row {
  margin: 0;
}
.bg-layer > .container > .row > [class*='col-'],
.bg-layer > .container-fluid > .row > [class*='col-'] {
  padding: 0;
}

/* ===============================================
    4.Row-Bg-Image  
------------------------*/

.bg-img1 {
  background-image: url(https://via.placeholder.com/1920X800/666666.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-img2 {
  background-image: url(https://via.placeholder.com/1920X800/666666.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .bg-img3 {
  background-image: url(../images/bg-image/row-bgimage-3.png);
  background-repeat: no-repeat;
  background-position: center top;
}
.bg-img4 {
  background-image: url(../images/bg-image/row-bgimage-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bg-img5 {
  background-image: url(../images/bg-image/row-bgimage-5.png);
  background-repeat: no-repeat;
  background-position: center top;
} */
.bg-img6 {
  background-image: url(https://via.placeholder.com/1920X479/444444.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .bg-img7 {
  background-image: url(../images/bg-image/row-bgimage-7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
/* .bg-img8 {
  background-image: url(../images/bg-image/row-bgimage-8.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
/* .map-bgimg,
.map-bgimg.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(../images/bg-image/map-bgimg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
/* ===============================================
    5.Col-Bg-Image  
------------------------*/

.ttm-equal-height-image {
  display: none;
}
.col-bg-img-one.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  /* background-image: url(../images/bg-image/col-bgimage-1.png) !important; */
  background-position: right !important;
  background-repeat: no-repeat !important;
}
.col-bg-img-two.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(https://via.placeholder.com/970X1100/444444.jpg) !important;
  background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.col-bg-img-three.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(https://via.placeholder.com/1305X730/444444.jpg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
/* .map-col-bg {
  background-image: url(../images/bg-image/map-col-bg.jpg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
} */
/* ===============================================
    6.highlight-text
------------------------*/

.highlight-text h2 {
  text-align: left;
  font-size: 90px;
  line-height: 90px;
  font-family: 'Poppins', Arial, Helvetica;
  font-weight: 600;
}
.highlight-text {
  /* background: url(../images/highlight-text-bg.jpg) no-repeat;  */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: center center;
}
.max-content {
  max-width: max-content !important;
  width: auto;
}

/* ===============================================
    7.Section-Title  
------------------------*/

.section-title {
  position: relative;
}
.section-title h5 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 12px;
  margin-top: 5px;
}
.section-title h2.title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.section-title .title-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #687076;
}
.section-title .title-header {
  margin-bottom: 29px;
  position: relative;
}
.section-title.with-desc .title-header {
  margin-bottom: 90px;
}
.section-title.with-desc .title-header:before {
  width: 43px;
  bottom: -35px;
  height: 4px;
  left: 50%;
  margin-left: -21px;
  top: auto;
  position: absolute;
  content: '';
}
.section-title.text-left.with-desc .title-header:before {
  left: 0;
  margin-left: 0;
}
.section-title.style2 {
  margin-bottom: 35px;
}
.section-title.style2 .title-header {
  width: 45%;
  padding-right: 60px;
  margin-bottom: 0;
}
.section-title.style2 .title-header,
.section-title.style2 .title-desc {
  display: table-cell;
  vertical-align: middle;
}
.row-title h2.title {
  font-size: 54px;
  line-height: 70px;
  margin-bottom: 0px;
  padding: 0;
}
/* ===============================================
    8.separator
------------------------*/

.separator .sep-line {
  height: 1px;
  border-top: 1px #ebebeb;
  display: block;
  position: relative;
  width: 100%;
}
.separator .sep-line.dashed {
  border-top-style: dashed;
}
.separator .sep-line.solid {
  border-top-style: solid;
}
.ttm-bgcolor-darkgrey .separator .sep-line {
  border-color: rgba(255, 255, 255, 0.14);
}
.ttm-bgcolor-white .separator .sep-line {
  border-color: #f1f1f1;
}

/* ===============================================
    9.Buttons
------------------------*/
.ttm-btn {
  display: inline-block;
  font-size: 16px;
  line-height: normal;
  padding: 11px 32px 11px 31px;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  font-weight: 600;
  overflow: hidden;
  z-index: 9;
  vertical-align: middle;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ttm-btn.ttm-icon-btn-left {
  padding-left: 44px;
}
.ttm-btn.ttm-icon-btn-right {
  padding-right: 44px;
}
.ttm-btn.ttm-icon-btn-left i {
  left: 20px;
}
.ttm-btn.ttm-icon-btn-right i {
  right: 20px;
}
.ttm-btn.ttm-icon-btn-right {
  padding-right: 22px;
}
.ttm-btn.ttm-icon-btn-right i {
  right: 2px;
}

.ttm-bgcolor-darkgrey .ttm-btn:hover.btn-inline.ttm-btn-color-skincolor {
  background-color: transparent;
  color: #fff;
}

/** btn-with-icon **/
.ttm-btn i {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.ttm-btn.ttm-icon-btn-right {
  text-align: right;
}
.ttm-btn.ttm-icon-btn-left {
  text-align: left;
}

/** btn-size-xs **/
.ttm-btn.ttm-btn-size-xs {
  font-size: 12px;
  padding: 7px 22px;
}
.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-right {
  padding-right: 31px;
}
.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-left {
  padding-left: 31px;
}
.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-left i {
  left: 12px;
}
.ttm-btn.ttm-btn-size-xs i {
  font-size: 14px;
  height: 11px;
  line-height: 11px;
  right: 12px;
}

/** btn-size-sm **/
.ttm-btn.ttm-btn-size-sm {
  font-size: 14px;
  padding: 10px 24px 10px 24px;
}
.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-right {
  padding-right: 39px;
}
.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-left {
  padding-left: 39px;
}
.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-left i {
  left: 16px;
}
.ttm-btn.ttm-btn-size-sm i {
  font-size: 11px;
  height: 12px;
  line-height: 12px;
  right: 16px;
}

/** btn-size-md **/
.ttm-btn.ttm-btn-size-md {
  font-size: 14px;
  padding: 13px 30px 13px 30px;
}
.ttm-btn.ttm-btn-size-md.ttm-icon-btn-right {
  padding-right: 48px;
}
.ttm-btn.ttm-btn-size-md.ttm-icon-btn-left {
  padding-left: 48px;
}
.ttm-btn.ttm-btn-size-md.ttm-icon-btn-left i {
  left: 20px;
}
.ttm-btn.ttm-btn-size-md.ttm-icon-btn-right i {
  font-size: 11px;
  height: 11px;
  line-height: 11px;
  right: 20px;
}

/** btn-size-lg **/
.ttm-btn.ttm-btn-size-lg {
  font-size: 20px;
  line-height: 20px;
  padding: 18px 45px;
}
.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-right {
  padding-right: 60px;
}
.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-left {
  padding-left: 60px;
}
.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-left i {
  left: 25px;
}
.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-right i {
  font-size: 20px;
  height: 16px;
  line-height: 16px;
  right: 25px;
}

/** btn-shape **/
.ttm-btn.ttm-btn-shape-round {
  border-radius: 2em;
}
.ttm-btn.ttm-btn-shape-rounded {
  border-radius: 5px;
}
.ttm-btn.ttm-btn-shape-square {
  border-radius: 0;
}

/** btn-style-border **/
.ttm-btn.ttm-btn-style-border {
  background-color: transparent;
  border: 1px solid;
}
.ttm-btn.ttm-btn-color-white.ttm-btn-style-border {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

/** btn-inline **/
.ttm-btn.btn-inline {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
  overflow: visible;
}
.ttm-btn.btn-inline:before,
.ttm-btn.btn-inline:after {
  position: absolute;
  content: '';
  bottom: -3px;
  left: auto;
  right: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.ttm-btn.btn-inline:before {
  width: 100%;
}
.ttm-btn.btn-inline:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}
/* videao-btn */
.ttm-video-btn.video-btn-left {
  left: 0;
}
.ttm-video-btn.text-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 165px;
}
.video-btn-left .ttm-play-btn {
  padding-left: 90px;
}
.ttm-video-btn.video-btn-center {
  left: 50%;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}
.ttm-video-btn:hover a {
  opacity: 0.9;
  color: #fff;
}
.ttm-play-btn {
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  display: block;
  padding: 10px 20px 10px 50px;
  position: relative;
}
.ttm-video-btn .ttm-btn-play,
.ttm-video-btn .ttm-video-btn-play {
  height: 65px;
  width: 65px;
  line-height: 65px;
  border-radius: 50%;
  padding-left: 7px;
  text-align: center;
  font-size: 30px;
  font-variant: normal;
  text-rendering: auto;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  -webkit-transform: translate(-25%, -10%);
  -ms-transform: translate(-25%, -10%);
  -o-transform: translate(-25%, -10%);
  transform: translate(-25%, -10%);
}
.ttm-btn-play:after,
.ttm-btn-play:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.ttm-btn-play:after {
  z-index: 1;
  width: 105px;
  height: 105px;
  opacity: 0.09;
}
.ttm-btn-play:before {
  z-index: 2;
  width: 94px;
  height: 94px;
  opacity: 0.21;
}
.ttm-play-btn:hover,
.ttm-video-btn span.ttm-btn-play {
  color: #fff;
}
.ttm-video-btn:hover .ttm-btn-play {
  background-color: rgba(253, 161, 43, 0.95);
  color: rgba(255, 255, 255, 1);
}

.ttm-video-btn .ttm-video-btn-play:before {
  width: 94px;
  height: 94px;
  opacity: 0.41;
}
.ttm-video-btn .ttm-video-btn-play:before,
.ttm-video-btn .ttm-video-btn-play:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
}
.ttm-video-btn .ttm-video-btn-play:after {
  z-index: 1;
  width: 105px;
  height: 105px;
  opacity: 0.15;
}
.ttm-video-btn .ttm-video-btn-play:hover:before,
.ttm-video-btn .ttm-video-btn-play:hover:after {
  -webkit-animation: prittyphoto 1.05s infinite;
  -moz-animation: prittyphoto 1.05s infinite;
  -ms-animation: prittyphoto 1.05s infinite;
  -o-animation: prittyphoto 1.05s infinite;
  animation: prittyphoto 1.05s infinite;
}
@-webkit-keyframes prittyphoto {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes prittyphoto {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@-webkit-keyframes sonarEffect {
  % {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
.ttm-video-btn.ttm-right-center-video-btn {
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  margin-top: -20px;
}
.ttm-video-btn.ttm-top-center-video-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  text-align: center;
}

/* ===============================================
    10.Icons
------------------------*/
.ttm-icon {
  margin-bottom: 35px;
  display: inline-block;
  text-align: center;
  border: 2px solid transparent;
  position: relative;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  box-sizing: content-box;
  position: relative;
}

.ttm-icon i {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/** icon-size-xs **/
.ttm-icon.ttm-icon_element-size-xs {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.ttm-icon.ttm-icon_element-size-xs i {
  font-size: 16px;
}

/** icon-size-sm **/
.ttm-icon.ttm-icon_element-size-sm {
  height: 50px;
  width: 50px;
  line-height: 50px;
}
.ttm-icon.ttm-icon_element-size-sm i {
  font-size: 28px;
}

/** icon-size-md **/
.ttm-icon.ttm-icon_element-size-md {
  height: 58px;
  width: 58px;
  line-height: 58px;
}
.ttm-icon.ttm-icon_element-size-md i {
  font-size: 30px;
}

/** icon-size-lg **/
.ttm-icon.ttm-icon_element-size-lg {
  height: 62px;
  width: 62px;
  line-height: 62px;
}
.ttm-icon.ttm-icon_element-size-lg i {
  font-size: 45px;
}

/** icon-shape **/
.ttm-icon.ttm-icon_element-style-rounded {
  border-radius: 5px;
}
.ttm-icon.ttm-icon_element-style-round {
  border-radius: 50%;
}
.ttm-icon.ttm-icon_element-style-square {
  border-radius: 0;
}

/** icon-style-border **/
.ttm-icon.ttm-icon-color-black.ttm-icon-style-border {
  background-color: transparent;
}

/* ===============================================
    11.Fid
------------------------*/
.inside {
  text-align: center;
  position: relative;
  padding: 29px 15px 25px 15px;
  margin-bottom: 30px;
}
.inside h4 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 30px;
  line-height: 34px;
}
.ttm-fid-view-righticon.inside h4,
.ttm-fid-view-lefticon.inside h4 {
  margin-bottom: 0;
  float: left;
  display: inline-block;
}
.inside h3 {
  margin-bottom: 0;
  bottom: -11px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding-top: 0;
  display: inline-block;
}
.ttm-fid-view-righticon .ttm-fid-left,
.ttm-fid-view-lefticon .ttm-fid-left {
  position: relative;
}
.ttm-fid-view-righticon .ttm-fid-icon-wrapper,
.ttm-fid-view-lefticon .ttm-fid-icon-wrapper {
  position: absolute;
  top: 0;
}
.ttm-fid-icon-wrapper i {
  font-size: 40px;
}
.ttm-fid-view-topicon .ttm-fid-icon-wrapper i {
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  -khtml-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.ttm-fid-view-lefticon.inside .ttm-fid-contents {
  padding-left: 55px;
}
.ttm-fid-view-righticon .ttm-fid-contents,
.ttm-fid-view-lefticon .ttm-fid-contents {
  clear: both;
  overflow: hidden;
}
.ttm-fid-view-topicon-row {
  border: 1px solid #e8ecef;
}
.ttm-fid-view-topicon-row .with-right-border:not(:last-child):after {
  content: '';
  height: 100%;
  width: 1px;
  background-color: #f1f1f1;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  top: 50%;
  -khtml-transform: translateX(0%) translateY(-50%);
  -moz-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  -o-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
}
.ttm-fid-view-topicon {
  padding: 40px 40px 20px;
}
.ttm-fid.ttm-fid-view-topicon .ttm-fid-icon-wrapper {
  margin-bottom: 10px;
  width: 122px;
  height: 61px;
  line-height: 61px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.inside.ttm-fid-view-topicon h4 {
  font-size: 53px;
  line-height: 56px;
}
.inside.ttm-fid-view-topicon h3 {
  position: relative;
}
.ttm-highlight-fid-style1 {
  position: absolute;
  padding: 20px 20px 20px 30px;
  max-width: 207px;
  bottom: 3%;
  left: 30px;
}
.ttm-highlight-fid-style1:after {
  position: absolute;
  content: '';
  bottom: 0;
  width: 0;
  height: 0;
  left: 20%;
  border: 20px solid transparent;
  border-bottom: 0;
  margin-left: -21px;
  margin-bottom: -19px;
}
.ttm-fid-view-lefticon.ttm-highlight-fid-style1 .ttm-fid-icon-wrapper {
  top: 5px;
}
.ttm-highlight-fid-style2 {
  position: absolute;
  background: #fff;
  padding: 15px 20px 15px 20px;
  border: 7px solid #e9f2fa;
  bottom: 0;
  left: 30px;
}
.ttm-fid-view-lefticon.ttm-highlight-fid-style2 .ttm-fid-icon-wrapper {
  top: 5px;
}
/* ===============================================
    12.featured-icon-box ( only contents )
------------------------*/
.featured-icon-box {
  position: relative;
}
.featured-icon-box .ttm-icon {
  margin-bottom: 0px;
}
.featured-icon-box.top-icon .featured-content {
  padding-top: 14px;
}
.featured-icon-box .ttm-icon.ttm-icon_element-size-md {
  height: 42px;
  width: 42px;
  line-height: 42px;
}
.featured-icon-box .ttm-icon.ttm-icon_element-fill.ttm-icon_element-size-md {
  height: 72px;
  width: 72px;
  line-height: 72px;
}
.featured-icon-box.left-icon {
  display: table;
}
.featured-icon-box.left-icon .featured-icon:not(.ttm-icon_element-border),
.featured-icon-box.left-icon .featured-icon,
.featured-icon-box.left-icon .featured-content {
  display: table-cell;
  vertical-align: middle;
}
.featured-icon-box.style1 .featured-content .featured-title h5 {
  margin-bottom: 0;
}
.featured-icon-box.left-icon.icon-align-top .featured-icon {
  vertical-align: top;
  padding-top: 5px;
}
.featured-icon-box.left-icon .featured-content {
  padding-left: 12px;
}
.featured-title h5 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.featured-icon-box.iconalign-before-heading {
  padding: 20px 0;
}
.featured-icon-box.iconalign-before-heading .featured-content {
  display: table;
}
.featured-icon-box.iconalign-before-heading .ttm-icon.ttm-icon_element-size-md {
  height: auto;
  width: auto;
}
.featured-icon-box.iconalign-before-heading .featured-content .ttm-icon,
.featured-icon-box.iconalign-before-heading .featured-content .featured-title {
  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;
}

.iconalign-before-heading-row:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.featured-icon-box.iconalign-before-heading.style1 {
  padding: 0;
}
.featured-icon-box.iconalign-before-heading.style1 .ttm-icon.ttm-icon_element-size-lg {
  height: 45px;
  width: 45px;
  line-height: 45px;
}

body {
  counter-reset: section;
}
.ttm-num:before {
  counter-increment: section;
  content: counter(section, decimal-leading-zero) ' ';
  font-size: 14px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.featured-icon-box.style1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 27px;
  width: 100%;
}
.featured-icon-box.style1 .featured-desc p {
  font-size: 12px;
  margin-bottom: 0;
}
.row > [class*='col-']:first-child > .featured-icon-box.style1 {
  padding-left: 0;
}
.row > [class*='col-']:not(:last-child) > .featured-icon-box.right-border {
  border-right: 1px dashed #ebebeb;
}
.featured-icon-box.style1.without-icon {
  padding-top: 40px;
  padding-left: 35px;
}
.featured-icon-box.without-icon.style2 .featured-title h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}
.featured-icon-box.without-icon.style2 {
  border: 1px solid #e8ecef;
  border-left: none;
  border-right: none;
  padding-top: 20px;
  padding-bottom: 5px;
  position: relative;
  padding: 20px 15px 5px;
}

.featured-icon-box.style2 {
  padding: 12px 15px 2px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.featured-icon-box.style2 .featured-title h5 {
  font-size: 18px;
  font-weight: 500;
}

.featured-icon-box.style3 {
  padding: 49px 25px 36px;
  position: relative;
  overflow: hidden;
  border: 2px dashed rgba(255, 255, 255, 0.1);
}
.featured-icon-box.style3:after,
.featured-icon-box.style3:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.55s, transform 0.55s;
}
.featured-icon-box.style3:after {
  border-left: 2px dashed;
  border-right: 2px dashed;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.featured-icon-box.style3:before {
  border-top: 2px dashed;
  border-bottom: 2px dashed;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.featured-icon-box.style3:hover:after,
.featured-icon-box.style3:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.featured-icon-box.style3:hover {
  border-color: transparent;
}

.featured-icon-box.style4 {
  padding: 0 55px 10px 0;
}
.featured-icon-box.style4 .featured-content .featured-title h5 {
  margin-bottom: 5px;
}
.featured-icon-box.style4 .ttm-icon.ttm-icon_element-size-xs,
.featured-icon-box.style4 .ttm-icon.ttm-icon_element-size-lg {
  height: 36px;
  width: 36px;
  line-height: 36px;
}
.featured-icon-box.style4 .ttm-icon.ttm-icon_element-size-xs i {
  font-size: 19px;
  font-weight: 600;
  line-height: 36px;
}
.featured-icon-box.style4 .featured-content {
  padding-left: 24px;
}

.featured-icon-box.style5 {
  padding: 45px 50px 50px 50px;
  margin-top: 10px;
}
.featured-icon-box.style5:after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #f3f3f3;
}
.featured-icon-box.style5 .ttm-icon.ttm-icon_element-size-lg,
.featured-icon-box.style9 .ttm-icon.ttm-icon_element-size-lg {
  height: 45px;
  width: 45px;
  line-height: 45px;
}
.featured-icon-box.style6 {
  padding: 20px 20px 10px 20px;
}
.featured-icon-box.style6 .ttm-icon.ttm-icon_element-size-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.featured-icon-box.style6 .ttm-icon.ttm-icon_element-size-lg i {
  font-size: 67px;
}
.featured-icon-box.style6 .featured-content .featured-desc p {
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
  font-weight: 600;
}
.featured-icon-box.style6 .featured-content .featured-title h5 {
  font-size: 25px;
  line-height: 25px;
}

.featured-icon-box.style7 {
  padding: 30px 15px 15px;
  border: 1px solid #ebedf2;
}
.featured-icon-box.style7 .featured-content .featured-title h5 {
  margin-bottom: 5px;
}
.featured-icon-box.style7 .ttm-icon.ttm-icon_element-size-lg i {
  font-size: 58px;
}
.featured-icon-box.style8 {
  border: 1px solid #e9e9e9;
  padding: 30px 30px 20px;
  margin-bottom: -1px;
}

.featured-icon-box.style9 .featured-content {
  padding-top: 10px;
}

.featured-icon-box.style10 .ttm-icon.ttm-icon_element-size-md {
  height: 80px;
  width: 80px;
  line-height: 80px;
}
.featured-icon-box.style10 .featured-content {
  padding-left: 34px;
}
.featured-icon-box.style10:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
/* ===============================================
    13.featured-imagebox ( contents with image)
------------------------*/
.featured-imagebox .ttm-box-view-content-inner {
  position: relative;
  overflow: hidden;
}
.featured-imagebox .ttm-box-bottom-content {
  padding: 35px 30px 42px 30px;
  text-align: left;
}
.featured-imagebox .ttm-box-bottom-content .ttm-icon {
  margin-bottom: 12px;
}
.ttm-box-bottom-content .ttm-icon.ttm-icon_element-size-md {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
.ttm-box-bottom-content .ttm-icon i {
  font-size: 40px;
}

/* portfolio */
.featured-imagebox-portfolio {
  position: relative;
  overflow: hidden;
}
.featured-imagebox-portfolio.ttm-box-view-top-image {
  margin-bottom: 30px;
  overflow: visible;
}
.featured-imagebox-portfolio .featured-content {
  padding: 21px 25px 27px;
  position: relative;
  background-color: #fff;
}
.featured-imagebox-portfolio .featured-content .featured-title h5 {
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.featured-imagebox-portfolio .ttm-box-view-overlay {
  position: absolute;
  bottom: -30%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 35, 51, 0.83);
}
.featured-imagebox-portfolio:hover .ttm-box-view-overlay {
  opacity: 1;
  bottom: 0;
}
.featured-imagebox-portfolio.ttm-box-view-top-image .featured-iconbox.ttm-media-link a {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 43px;
  width: 43px;
  line-height: 43px;
  border: 1px solid transparent;
  text-align: center;
  color: #fff;
}
.featured-imagebox-portfolio.ttm-box-view-top-image .featured-iconbox.ttm-media-link a {
  top: 50%;
  right: 50%;
  margin-top: -17px;
}
.featured-imagebox-portfolio a.ttm_link {
  line-height: 41px;
  margin-right: -47px;
  float: right;
}
/* portfolio-style1 */
.featured-imagebox-portfolio-style1 {
  position: relative;
  overflow: hidden;
}
.featured-imagebox-portfolio-style1 .featured-content-portfolio {
  position: absolute;
  bottom: 0;
  background-color: rgba(24, 35, 51, 1);
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 15px;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 2;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.featured-imagebox-portfolio-style1:hover .featured-content-portfolio {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}
.featured-imagebox-portfolio-style1 .featured-thumbnail {
  position: relative;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform, -webkit-box-shadow;
  transition-property: -webkit-transform, -webkit-box-shadow;
  transition-property: transform, box-shadow;
  transition-property: transform, box-shadow, -webkit-transform, -webkit-box-shadow;
}
.featured-imagebox-portfolio-style1:hover .featured-thumbnail {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}
.featured-imagebox-portfolio-style1 .featured-title h5,
.featured-imagebox-portfolio .featured-title h5 {
  font-weight: 500;
}

/* team */
.featured-thumbnail {
  position: relative;
  overflow: hidden;
}
.featured-imagebox-team {
  overflow: hidden;
  position: relative;
}
.featured-imagebox-team .featured-content-team.category {
  font-size: 12px;
  color: #687076;
}
.featured-imagebox-team .ttm-social-links-wrapper {
  margin: 0;
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 99;
}
.featured-imagebox-team .ttm-social-links-wrapper ul {
  margin: 0;
  padding: 3px 14px;
}
.featured-imagebox-team .ttm-social-links-wrapper ul li a {
  display: block;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
}
.featured-imagebox-team .featured-content-team {
  text-align: center;
  z-index: 2;
  position: relative;
  padding: 23px 10px 10px;
}
.featured-imagebox-team .featured-title h5 {
  margin-bottom: 0;
}
.featured-imagebox-team:hover .featured-thumbnail img {
  transform: scale(1.05);
}
.featured-imagebox-team.style2 .featured-thumbnail:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #182333;
  opacity: 0.36;
  z-index: 1;
}
.featured-imagebox-team.style2 .featured-thumbnail img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
  position: relative;
}
.featured-imagebox-team.style2 .ttm-team-box-view-overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #fff;
  z-index: 1;
  padding: 20px;
}
.featured-imagebox-team.style2 .ttm-social-links-wrapper {
  margin: 0;
  width: 100%;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
}
.featured-imagebox-team.style2 .ttm-social-links-wrapper ul li a {
  font-size: 22px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: transparent;
}
.featured-imagebox-team.style2 .ttm-social-links-wrapper ul li a:hover {
  background-color: transparent;
  color: #fff;
}
.featured-imagebox-team.style2 .featured-content.featured-content-team {
  padding: 30px;
  padding-bottom: 19px;
  transition-duration: 0.3s;
  color: #fff;
  text-align: center;
  background-color: transparent;
}
.featured-imagebox-team.style2:hover .ttm-team-box-view-overlay {
  bottom: 0;
}

/* team-details */
.featured-imagebox-team.featured-imagebox-team-details .featured-content-team {
  background-color: #fff;
}
.featured-imagebox-team.featured-imagebox-team-details .ttm-social-links-wrapper {
  position: relative;
  bottom: auto;
}
.featured-imagebox-team.featured-imagebox-team-details .featured-content-team {
  display: block;
  clear: both;
  padding-top: 30px;
  border-bottom: 5px solid;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
/* services */
.featured-imagebox-services {
  background-color: #fff;
  padding: 0;
  margin: 10px 0 20px;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.featured-imagebox-services .featured-title {
  padding: 23px 15px;
}
.featured-imagebox-services .featured-title h5 {
  margin-bottom: 0;
}
.featured-imagebox-services .featured-content {
  padding: 25px 25px 25px;
}
.featured-imagebox-services .ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white {
  border-color: #e8ecef;
  margin-bottom: 0;
}
.featured-imagebox-services .ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white i {
  color: #e8ecef;
}
.featured-imagebox-services:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.14);
}
/* post */
.featured-imagebox-post {
  background-color: #fff;
  padding: 0;
  margin-bottom: 20px;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.featured-imagebox-post .featured-content.featured-content-post {
  padding: 22px 27px 22px 27px;
}
.featured-imagebox-post .featured-content.featured-content-post .post-meta {
  display: block;
  padding: 7px 3px 3px 0;
  font-size: 12px;
}
.featured-imagebox-post .featured-content.featured-content-post .post-meta span {
  padding-right: 10px;
  position: relative;
}
.featured-imagebox-post .featured-content.featured-content-post .post-meta span i {
  padding-right: 5px;
}
.featured-imagebox-post .featured-content.featured-content-post .post-title h5 {
  margin: 0;
  font-size: 19px;
  line-height: 28px;
}
.featured-imagebox-post:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  box-shadow: 0 0 17px rgba(32, 46, 60, 0.1);
}
.ttm-post-format-video {
  padding-bottom: 68.25%;
  position: relative;
  overflow: hidden;
  padding-top: 25px;
}
.ttm-post-format-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.featured-imagebox-post .featured-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}
.featured-imagebox-post .featured-icon .ttm-icon.ttm-icon_element-size-xs {
  width: 38px;
  height: 38px;
  line-height: 38px;
}
.featured-imagebox-post .featured-icon .ttm-icon.ttm-icon_element-size-xs i {
  font-size: 17px;
}

.ttm-box-view-left-image.featured-imagebox-post {
  padding: 15px;
}
.ttm-box-view-left-image .ttm-featured-img-left {
  padding: 0;
}
.ttm-box-view-left-image .featured-content,
.ttm-box-view-left-image.featured-imagebox-post .featured-content {
  padding-left: 25px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 0 !important;
}
.featured-imagebox-post.ttm-box-view-left-image .featured-content.featured-content-post {
  padding: 15px 17px 11px 17px;
}
.featured-imagebox .featured-content .category,
.featured-imagebox .featured-content .category a {
  color: #9dabaf;
}
.ttm-box-view-left-image .featured-content .category {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}
.featured-imagebox .featured-content .category,
.featured-imagebox .featured-content .category a {
  font-size: 15px;
}
.ttm-box-view-left-image .featured-content .category > a {
  display: inline-block;
  border: 1px solid;
  padding: 5px 10px;
  margin-right: 1px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 5px;
}
.featured-imagebox-post .featured-content.featured-content-post .post-title h5 {
  margin: 0;
  font-size: 19px;
  line-height: 28px;
}
.ttm-box-view-left-image .featured-content .ttm-box-desc,
.ttm-box-view-left-image .featured-content .featured-desc {
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;
  padding-top: 7px;
}
.featured-imagebox-post.ttm-box-view-left-image
  .featured-content.featured-content-post
  .post-meta
  span {
  padding: 0;
  font-size: 14px;
}
/* ===============================================
    14.Processbox
------------------------*/
.ttm-processbox-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin: 0 -50px;
}
.ttm-processbox-wrapper .ttm-processbox {
  position: relative;
  display: block;
  text-align: center;
  width: 270px;
  height: 270px;
  background: #fff;
  padding: 20px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  -webkit-box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  -moz-box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  transition: all 500ms ease;
}
.ttm-processbox-wrapper .ttm-processbox .process-num {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 500;
  background-color: #000;
  color: #fff;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  border: none;
  display: block;
  text-align: center;
  position: absolute;
  left: auto;
  right: 30px;
  top: 20px;
  z-index: 10;
}
.ttm-processbox-wrapper .featured-content {
  position: relative;
  border-radius: 50%;
  padding: 65px 15px 60px 15px;
  width: 230px;
  height: 230px;
  border: 6px solid #e8ecef;
}
.ttm-processbox-wrapper .ttm-processbox .featured-title h5 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}
.ttm-processbox-wrapper .ttm-processbox:hover {
  box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  -webkit-box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  -moz-box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  transform: translateY(-10px);
}
/* ===============================================
    15.Progress-Bar
------------------------*/
.ttm-progress-bar {
  position: relative;
}
.ttm-progress-bar {
  margin: 0 0 15px 0;
}
.ttm-progress-bar:not(:last-child) {
  margin-bottom: 30px;
}
.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_black {
  background-color: #414141;
}
.ttm-progress-bar h4 {
  top: -35px;
  left: 0;
  font-size: 17px;
  color: #5d6576;
  font-weight: 400;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
}
.ttm-progress-bar i {
  position: absolute;
  font-size: 21px;
}
.ttm-progress-bar .progress {
  background-color: #c0c0c1;
  border-radius: 0;
  height: 7px;
}
.ttm-progress-bar .progress-parcent {
  position: absolute;
  right: 0;
  color: #0a0a0a;
  z-index: 3;
  text-shadow: none;
  font-size: 17px;
  padding: 2px 0;
  border-radius: 0;
  top: 0;
}
.ttm-icon-left-true.ttm-progress-bar h4 {
  margin-left: 35px;
}
.ttm-progress-bar.ttm-icon-left-true {
  margin-bottom: 24px;
}

/* ===============================================
    16.Testimonial
------------------------*/
.testimonials {
  position: relative;
}
.testimonials .testimonial-content {
  text-align: center;
  margin: 12px 120px 8px;
  padding: 0 25px;
}
.testimonials .testimonial-content .testimonial-caption {
  margin-top: 15px;
  margin-bottom: 15px;
}
.testimonial-content .testimonial-avatar {
  display: block;
  margin: 0 auto;
}
.testimonials.style1 .testimonial-content .testimonial-avatar {
  position: relative;
  height: 95px;
  width: 95px;
}
.testimonials.style1 .testimonial-content .testimonial-avatar:after {
  content: '\f10d';
  font-family: 'FontAwesome';
  font-size: 14px;
  position: absolute;
  line-height: 33px;
  top: 5px;
  left: -10px;
  width: 31px;
  height: 31px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
}
.testimonials .testimonial-content h6 {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 0;
}
.testimonials .testimonial-content label {
  display: block;
  font-size: 13px;
  margin-bottom: 0;
  color: #687076;
}
.testimonials .testimonial-content blockquote {
  padding: 0 10px 0 25px;
  font-style: italic;
  font-weight: 400;
  font-size: 17px;
  background-color: transparent;
  line-height: 30px;
  position: relative;
  margin-bottom: 27px;
}
.testimonial-content .ttm-ratting-star i {
  font-size: 18px;
}
.testimonials .testimonial-avatar .testimonial-img img {
  height: 95px;
  width: 95px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: 3px solid #fff;
}

.testimonials.style1 .ttm-ratting-star {
  padding-top: 15px;
  padding-bottom: 10px;
  color: #fff;
}
.testimonials.style2 {
  position: relative;
}
.testimonials.style2:before {
  position: absolute;
  content: '';
  background: #f1f6fb;
  right: 0;
  left: 15px;
  top: -15px;
  width: 100%;
  height: 100%;
}
.testimonials.style2 .testimonial-content {
  position: relative;
  background-color: #fff;
  padding: 60px 35px 50px 35px;
  transition: 0.5s ease;
  box-shadow: 0 0px 15px 0 rgba(8, 18, 109, 0.1);
  display: block;
  overflow: hidden;
  margin: 0;
  border-bottom: 3px solid transparent;
}
.testimonials.style2 .testimonial-content:hover {
  transform: translate(8px, -8px);
}
.testimonials.style2 .testimonial-content blockquote {
  border: none;
  margin: 0;
  outline: none;
  text-align: left;
  position: relative;
  color: #687076;
  font-style: italic;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: 17px;
  background-color: transparent;
  line-height: 29px;
  padding-right: 10px;
  margin-bottom: 30px;
}
.testimonials.style2 .testimonial-content blockquote:after {
  content: '\f10d';
  font-family: 'FontAwesome';
  color: #0b0c26;
  font-size: 40px;
  line-height: 50px;
  position: absolute;
  top: -12px;
  left: 0;
  opacity: 0.1;
}
.testimonials.style2 .testimonial-avatar,
.testimonials.style2 .testimonial-caption {
  display: inline-block;
  float: left;
}
.testimonials.style2 .testimonial-caption {
  padding-left: 15px;
}
.testimonials.style2 .ttm-ratting-star i {
  font-size: 14px;
}
.testimonials.style2 .testimonial-content label {
  color: #687076 !important;
}

/* ===============================================
    17.Client-row  
------------------------*/
.client-box {
  margin-bottom: 20px;
  padding: 15px 12px 30px;
}
.ttm-client-logo-tooltip {
  position: relative;
}
.ttm-box-view-separator-logo .client-box .client:after {
  border-right: 1px solid #eaeaea;
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  right: -25px;
  top: 0;
}
.ttm-box-view-separator-logo .row .col-sm-4:nth-child(3n + 3) .client-box .client:after {
  display: none;
}
.client-box.ttm-box-view-boxed-logo {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}
.client-box.ttm-box-view-boxed-logo .ttm-client-logo-tooltip {
  margin-right: 13px;
  margin-left: 13px;
  padding: 18px 12px;
  display: table-cell;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}
.client-box.ttm-box-view-boxed-logo .client {
  width: 100%;
  display: table;
}
.client-box.ttm-box-view-boxed-logo .client:hover img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.client-box.ttm-box-view-boxed-logo {
  position: relative;
  display: block;
}

/* ===============================================
    18.Accordion
------------------------*/
.wrap-acadion .accordion {
  margin-top: 15px;
}
.accordion .toggle .toggle-title {
  font-size: 17px;
  cursor: pointer;
  padding: 14px 20px;
  font-weight: 400;
  margin-bottom: 0;
  position: relative;
}
.accordion .toggle-title a {
  color: #fff;
}
.accordion .toggle-title a i {
  margin-right: 14px;
}
.accordion .toggle.ttm-style-classic .toggle-title {
  border: 1px solid #f7f7f7;
  background-color: transparent;
}

.accordion .ttm-style-classic .toggle-title.active {
  border-color: #f0f0f0;
  background-color: #f8f8f8;
}
.toggle-content p {
  margin-bottom: 0;
}
.toggle-content ul li {
  list-style: disc;
}
.accordion .ttm-style-classic .toggle-title > a,
.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey.active .toggle-title > a,
.accordion .ttm-toggle-title-bgcolor-grey.active .toggle-title:after {
  color: #666;
}
.accordion .toggle-title:after.active,
.accordion .toggle:not(.ttm-style-classic) .toggle-title:after.active {
  color: #fff;
}
.accordion .toggle:not(:first-child) .toggle-title {
  margin-top: 10px;
}
.accordion .toggle:not(:last-child):not(.active) .toggle-title {
  margin-bottom: 10px;
}

.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey .toggle-title {
  border-color: #f0f0f0;
  background-color: #f5faff;
}
.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey.active .toggle-title {
  background-color: #fff;
}
.accordion .toggle .toggle-title:after {
  position: absolute;
  font-family: 'themify';
  right: 10px;
  top: 15px;
  content: '\e61a';
}
.accordion .toggle.active .toggle-title:after {
  content: '\e622';
  font-family: 'themify';
}
.accordion .toggle-content {
  display: none;
  padding: 14px 20px;
  overflow: hidden;
}
.accordion .toggle.ttm-toggle-title-bgcolor-grey .toggle-title:after {
  position: absolute;
  font-family: 'FontAwesome';
  content: '\f078';
  right: 15px;
  font-size: 12px;
  top: 15px;
}
.accordion .toggle.ttm-toggle-title-bgcolor-grey.active .toggle-title:after {
  font-family: 'FontAwesome';
  content: '\f077';
}
.accordion .toggle.active .toggle-title:after {
  color: #fff;
}
.accordion .toggle.ttm-toggle-title-bgcolor-grey .toggle-title:after {
  color: #666;
}

.accordion .ttm-style-classic.ttm-toggle-title-border .toggle-title {
  background-color: transparent;
  border: 2px solid #e3e3e3;
}
.accordion .toggle.ttm-style-classic.ttm-toggle-title-border .toggle-title {
  padding: 18px 20px;
  line-height: 1;
}

.accordion.style2 {
  padding: 15px 30px 40px;
  border: 1px solid #e9e9e9;
}
.accordion.style2 .ttm-style-classic.ttm-toggle-title-border .toggle-title {
  border: none;
  border-bottom: 1px solid #efefef;
}
.accordion.style2 .ttm-style-classic.ttm-toggle-title-border.active .toggle-title {
  border-color: transparent !important;
  background-color: transparent;
}
.accordion.style2 .toggle-content {
  padding: 35px 30px;
}
.accordion.style2 .toggle-content h6 {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
}
.accordion.style2 .toggle-content p {
  margin-bottom: 15px;
}
.accordion.style2 .toggle.active .toggle-title:after {
  color: #8d9297;
}
/* ===============================================
    19.Wrap-Form
------------------------*/
.wrap-form label {
  width: 100%;
  margin: 0;
}
.wrap-form span.text-input {
  margin-bottom: 15px;
  display: block;
  padding-top: 5px;
  position: relative;
}
/* quote-form */
.ttm-quote-form input[type='text'],
.ttm-quote-form textarea,
.ttm-quote-form select {
  border: 1px solid #f8f9fa;
  background-color: #f8f9fa;
  width: 100%;
  font-size: 13px;
}
.ttm-quote-form .text-input textarea {
  min-height: 100%;
}
.ttm-quote-form button {
  margin-top: 10px;
}
.ttm-quote-form .text-input input::placeholder,
.ttm-quote-form .text-input textarea::placeholder {
  color: #828282;
}
.ttm-quote-form input[type='submit'] {
  padding: 10px 40px;
  margin-top: 5px;
  width: 100%;
}
.wrap-form.ttm-contactform span.text-input i {
  position: absolute;
  left: 0;
  top: 20px;
  opacity: 0.9;
  font-size: 16px;
  z-index: 1;
}
.wrap-form.ttm-contactform span.text-input input,
.wrap-form.ttm-contactform span.text-input textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 0;
  border-radius: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.wrap-form.ttm-contactform input[type='submit'] {
  margin-top: 30px;
}

/* ===============================================
    20.Tab
------------------------*/
.ttm-tabs {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ttm-tabs .section-title {
  padding-bottom: 0;
}
.ttm-tabs ul {
  margin: 0;
  padding: 0;
}
.ttm-tabs.style2 ul {
  display: block;
  margin-bottom: 35px;
}
.ttm-tabs ul.tabs li {
  display: inline-block;
}
.ttm-tabs ul.tabs li a {
  display: block;
  cursor: pointer;
  padding: 8px 22px;
  margin-bottom: 5px;
  line-height: 22px;
  width: auto;
  position: relative;
  z-index: 3;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #0b0c26;
}
.ttm-tabs.style2 ul.tabs li a:before {
  position: absolute;
  content: '';
  left: 0;
  top: -1px;
  width: 100%;
  height: 3px;
  opacity: 0;
}
.ttm-tabs.style2 ul.tabs li.active a:before {
  opacity: 1;
}

.ttm-tabs ul.tabs li a i {
  font-size: 18px;
  margin-right: 9px;
  position: relative;
  top: 2px;
}
.ttm-tabs ul.tabs li a span {
  font-size: 18px;
  font-weight: normal;
}
.tab-padding-box {
  padding-top: 105px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 70px;
}
.ttm-tabs .content-tab {
  margin-top: 20px;
  width: 100%;
}
.ttm-tabs .content-tab h6 {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 29px;
  padding-bottom: 7px;
}
.ttm-tabs.style2 .tabs {
  margin-bottom: 0px;
  padding-left: 0px;
}
.ttm-tabs.style2.full-width .tabs {
  padding-left: 10px;
}
.ttm-tabs.style2 .content-tab {
  padding: 0;
}
.ttm-tabs.style2 .row.ttm-boxes-spacing-10px {
  padding: 0;
}
.ttm-tabs.style2 ul.tabs li {
  margin-right: 7px;
  background: transparent;
  padding: 0;
}
.ttm-tabs.style2 ul.tabs li a {
  display: block;
  cursor: pointer;
  padding: 12px 30px;
  line-height: 22px;
  position: relative;
  z-index: 3;
  color: #1c1c1c;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  margin-bottom: 0;
}
.ttm-tabs.style2 ul.tabs li.active a {
  border-color: rgba(0, 0, 0, 0.2) !important;
}
/* ttm-tab-style-vertical */
.ttm-tabs.ttm-tab-style-vertical .content-tab {
  padding: 0;
  margin: 0;
}
.ttm-tabs.ttm-tab-style-vertical ul.tabs li {
  margin-top: 1px;
  width: 100%;
  display: block;
}
.ttm-tabs.ttm-tab-style-vertical ul.tabs li a {
  background-color: #2a2a2a;
  color: #fff;
  letter-spacing: 1.2px;
  padding: 14px 20px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  border-radius: 0;
}

/* ttm-tab-style-horizontal */
.ttm-tabs.ttm-tab-style-horizontal ul {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}
.ttm-tabs.ttm-tab-style-horizontal ul.tabs li {
  margin-right: 6px;
  display: inline-block;
}
.ttm-tabs.ttm-tab-style-horizontal ul.tabs li:last-child {
  margin-right: 0;
}
.ttm-tabs.ttm-tab-style-horizontal ul.tabs li a {
  padding: 16px 18px 15px 18px;
  font-size: 15px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  border: none;
  color: #fff;
  display: block;
  text-align: left;
}
.ttm-tabs.ttm-tab-style-horizontal .content-tab img {
  height: 150px;
  width: 150px;
}

.ttm-tab-color-grey.ttm-tab-style-classic ul li {
  margin-top: 3px;
  margin-bottom: 2px;
  margin-left: 3px;
  margin-right: 2px;
}
.ttm-tab-color-grey.ttm-tab-style-classic ul li a {
  font-size: 18px;
  text-transform: capitalize;
  padding: 12px 25px;
  border-top: 3px solid;
  border-radius: 0;
}
.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li.active a {
  color: #666;
  border-color: #f0f0f0;
}
.ttm-tab-color-grey.ttm-tab-style-classic ul li a {
  border-color: #e8e8e8;
  background-color: #e8e8e8;
}
.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li:hover a {
  background-color: #dcdcdc;
}
.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li.active a {
  background-color: #fff;
}

.ttm-tabs.ttm-tab-style-classic ul.tabs {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  justify-content: center;
}
.ttm-tabs.ttm-tab-style-classic ul.tabs li {
  text-align: center;
  margin-bottom: 5px;
}
.ttm-tabs.ttm-tab-style-classic ul.tabs li a {
  padding: 12px 25px;
  font-size: 16px;
  border-color: #ddd !important;
  background-color: #ebebeb;
  border: 1px solid;
  border-radius: 0;
  margin-bottom: 0;
  color: #666;
  text-transform: capitalize;
}
.ttm-tabs.ttm-tab-style-classic ul.tabs li.active a {
  color: #666;
  border-top: 3px solid;
  border-color: #f0f0f0 !important;
  background-color: #f8f8f8;
  margin-top: -2px;
}
.ttm-tabs.ttm-tab-style-classic ul.tabs li.active:after {
  display: block;
  content: '';
  position: relative;
  bottom: 7px;
  left: 50%;
  width: 13px;
  height: 13px;
  border-width: 0 0 1px 1px;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
}
.ttm-tabs.ttm-tab-style-classic .content-tab {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: padding 0.2s ease-in-out;
  -o-transition: padding 0.2s ease-in-out;
  transition: padding 0.2s ease-in-out;
  background: #fff;
  padding: 30px 25px;
}
/* ===============================================
    21.Boxes-Spacing
------------------------*/
.row.ttm-boxes-spacing-10px {
  margin: 0px -1px;
}
.ttm-boxes-spacing-10px .ttm-box-col-wrapper {
  padding-right: 5px;
  padding-left: 5px;
  margin: 0 0 10px;
}
.row.ttm-boxes-spacing-10px.style2 {
  padding: 10px 0 0;
  margin: 0 -5px;
}

.row.ttm-boxes-spacing-5px {
  padding: 10px 5px 0px;
  margin: 0;
}
.ttm-boxes-spacing-5px .ttm-box-col-wrapper {
  padding-right: 2.5px;
  padding-left: 2.5px;
  margin-bottom: 5px;
}
.row.ttm-boxes-spacing-5px.style2 {
  padding: 5px 0 0;
  margin: 0 -5px;
}

/* ===============================================
    22.Pricing-Plan
------------------------*/
.ttm-pricing-plan {
  position: relative;
  padding: 40px 20px 20px;
  text-align: center;
  margin-bottom: 30px;
  display: block;
  border: 2px solid #fff;
  transition: 0.5s ease;
}
.ttm-pricing-plan:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.ttm-ptablebox-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
.ttm-ptablebox-content {
  background: #f5faff;
  border: 1px solid #e8ecef;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ttm-ptablebox-price-w {
  position: relative;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 20px;
}
.ttm-ptablebox-features {
  padding: 5px 35px;
}
.ttm-pricing-plan .ttm-ptablebox-price {
  padding-left: 20px;
  font-size: 70px;
  line-height: 55px;
  font-weight: 700;
}
.ttm-pricing-plan .ttm-ptablebox-frequency:before {
  content: '/ ';
}
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-after,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  font-size: 20px;
  font-weight: 600;
}
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  position: absolute;
  bottom: 0;
}
.ttm-currency-before .ttm-ptablebox-price {
  padding-left: 20px;
  font-size: 50px;
  line-height: 40px;
  font-weight: 600;
}
.ttm-pricing-plan .ttm-ptablebox-frequency {
  position: relative;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}
.ttm-ptablebox-price,
.ttm-ptablebox-frequency,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-after,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  display: inline-block;
}
.ttm-ptablebox-features .ttm-feature-lines {
  list-style-type: none;
  margin: 0;
  padding: 12px 0 20px 0;
}
.ttm-ptablebox-features .ttm-feature-lines li {
  padding: 0;
  margin: 0;
  padding-top: 12px;
  padding-bottom: 11px;
}
.ttm-ptablebox-features .ttm-feature-lines li:not(:last-child) {
  border-bottom: 1px solid #e8ecef;
}
.price_btn {
  display: block;
  padding-top: 40px;
  text-align: center;
}
.price_btn .ttm-btn.ttm-btn-size-md {
  padding-left: 60px;
  padding-right: 60px;
}
.ttm-ptablebox-featured-col {
  box-shadow: 0 0 30px rgba(24, 35, 51, 0.06);
  padding: 50px 40px 68px;
  z-index: 1;
  background-color: #fff;
  overflow: visible;
}
.ttm-pricing-plan .ttm-featured-title {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  color: #fff;
  padding: 10px 15px;
  background-color: #0b0c26;
}

/* ===============================================
    23.Sidebar
------------------------*/
.sidebar .widget-area .widget {
  position: relative;
  margin: 40px 0 40px;
}
.sidebar .widget a {
  font-weight: 500;
  font-size: 14px;
}
.sidebar .widget .widget-title {
  font-size: 22px;
}
.sidebar .widget ul > li:last-child {
  padding-bottom: 0 !important;
}
.sidebar .content-area {
  padding-top: 65px;
  padding-bottom: 55px;
}
.ttm-sidebar-left div.content-area {
  float: right;
}
.ttm-sidebar-left div.widget-area {
  float: left;
}
.sidebar div.widget-area {
  position: relative;
  padding: 0px 30px;
  margin-top: 65px;
}
.sidebar .sidebar-right,
.sidebar .sidebar-left {
  margin-top: -37px;
  padding-top: 60px;
  padding-bottom: 70px;
  float: right;
}
.sidebar .sep_holder .sep_line {
  border-color: #ebebeb;
}

/*author-widget*/
.ttm-author-widget {
  text-align: center;
}
.ttm-author-widget .author-widget_img img {
  margin-bottom: 20px;
  width: 175px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.ttm-author-widget .author-name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  display: none;
}
.ttm-author-widget .author-widget_text {
  line-height: 30px;
  margin: 0;
  padding: 0 2px;
  padding-bottom: 15px;
}

/*widget-search*/
.sidebar .widget.widget-search {
  position: relative;
}
.sidebar .widget.widget-search .search-form label {
  display: block;
}
.sidebar .widget-search .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.sidebar .widget.widget-search .input-text {
  border: 1px solid #fff;
  border-radius: 0;
  padding: 10px 15px;
  -webkit-box-shadow: 0 0 10px 0 rgba(3, 59, 74, 0.05);
  -moz-box-shadow: 0 0 10px 0 rgba(3, 59, 74, 0.05);
  box-shadow: 0 0 10px 0 rgba(3, 59, 74, 0.05);
}
.widget.widget-search label i {
  position: absolute;
  right: 15px;
  cursor: pointer;
  font-size: 18px;
  z-index: 1;
  top: 35%;
}

/*widget-categories*/
.sidebar .widget_product_categories li,
.sidebar .widget_categories li {
  position: relative;
  margin: 5px 0;
  padding-bottom: 5px !important;
}
.sidebar .widget_product_categories li span,
.sidebar .widget-Categories li span {
  position: absolute;
  right: 0;
  top: 13px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

/** ttm-recent-post-list **/
ul.ttm-recent-post-list > li {
  padding: 5px 0 15px !important;
  display: inline-block;
  width: 100%;
  line-height: 20px;
}
ul.ttm-recent-post-list > li img {
  display: block;
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 18px;
}
ul.ttm-recent-post-list > li .post-date {
  display: block;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 5px;
}

/* tagcloud-widget */
.sidebar .widget .tagcloud a {
  display: inline-block;
  padding: 4px 10px;
  margin: 0 5px 10px;
  border: 1px solid;
  border-radius: 0;
  background-color: transparent;
}

/* widget-nav-menu */
.widget.widget-nav-menu ul li {
  margin-bottom: 2px;
  padding: 0;
}
.widget.widget-nav-menu ul li a {
  display: block;
  padding: 14px 30px 14px 24px;
  position: relative;
  background-color: #fff;
  z-index: 1;
  font-weight: 500;
  font-size: 15px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.widget.widget-nav-menu ul li a:before {
  content: '';
  width: 4px;
  top: 0;
  left: 0;
  background: #ebf3f9;
  height: 100%;
  position: absolute;
  -webkit-transition: background 0.2s linear;
  transition: background 0.2s linear;
  z-index: 1;
}
.widget.widget-nav-menu ul li a:after {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  z-index: -2;
}
.widget.widget-nav-menu ul li:hover a:after,
.widget.widget-nav-menu ul li.active a:after {
  width: 100%;
}
/* categories-widget */
.sidebar .widget-categories ul li {
  padding: 12px 0;
  display: block;
  position: relative;
}
.sidebar .widget-categories ul li:not(:last-child) {
  border-bottom: 2px solid #f5faff;
}
.sidebar .widget-categories ul li span {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #687076;
  background-color: #f5faff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* product-list-widget */
.sidebar .widget-top-rated-products ul.product-list-widget > li {
  border-top: 2px solid #f5faff;
  padding-bottom: 15px;
  padding-top: 15px;
  margin: 0;
  list-style: none;
  display: block;
}
.sidebar .widget-top-rated-products ul.product-list-widget > li:first-child {
  border-top: none;
  padding-top: 0;
}
.sidebar .widget-top-rated-products li a {
  display: block;
}
.sidebar .widget-top-rated-products li a img {
  width: 80px;
  float: left;
  margin-left: 0;
  margin-right: 15px;
}
.widget-top-rated-products .star-ratings ul li {
  padding-bottom: 0;
}
.widget-top-rated-products .star-ratings ul {
  margin-top: -5px;
}
.Price-amount.amount ins {
  padding: 2px 4px;
  text-decoration: none;
}

/* contact-widget */
.sidebar .widget-area .widget.contact-widget,
.sidebar .widget-area .widget.post-widget,
.sidebar .widget-area .widget.tagcloud-widget,
.sidebar .widget-area .widget-categories,
.sidebar .widget-area .widget-top-rated-products,
.sidebar .widget-area .widget-text {
  padding: 30px 20px;
  background-color: #fff;
}
.sidebar ul.contact-widget-wrapper li i {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 20px;
}
.sidebar ul.contact-widget-wrapper li {
  padding-left: 30px;
  position: relative;
  padding-top: 0;
  padding-bottom: 15px;
}
/* download-widget */
.widget-download .ttm-icon {
  margin-bottom: 0;
  font-size: 34px;
  position: absolute;
  top: 0%;
  left: 0%;
}
.widget-download ul.download li {
  display: block;
  margin-bottom: 15px;
  display: block;
  padding: 10px 9px 10px 60px;
}
.widget-download ul.download li:last-child {
  padding-bottom: 9px !important;
}
/* pagination */
.ttm-pagination {
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
.ttm-pagination .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 39px;
  text-align: center;
  display: inline-block;
  background-color: #f8f9fa;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #f8f9fa;
  border-radius: 0;
  margin: 0 3px;
  padding: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* ===============================================
    24.Products
------------------------*/
.ttm-product-box {
  margin-bottom: 35px;
}
.ttm-product-box-inner {
  position: relative;
  overflow: hidden;
}
.products-result-count {
  float: left;
  margin-top: 13px;
}
.products-ordering {
  margin-bottom: 36px;
  float: right;
}
.products-ordering select {
  background-color: #fff;
  text-indent: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
}
.products-ordering .orderby {
  position: relative;
}
.products-ordering .orderby:after {
  content: '\f0d7';
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 14px;
}
.products {
  clear: both;
}
.products .product .ttm-shop-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: table;
  width: 100%;
  max-width: 100%;
  z-index: 3;
  text-align: center;
}
.ttm-shop-icon .product-btn {
  height: 48px;
  line-height: 46px;
  font-size: 14px;
  padding: 0;
  display: table-cell;
  vertical-align: middle;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  transition: all 500ms;
  -webkit-transition: all 500ms;
  -moz-transition: all 500ms;
  -o-transition: all 500ms;
}
.products .product:hover .product-btn {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.products .product:hover .ttm-shop-icon {
  visibility: visible;
  margin-top: 0;
  bottom: 0;
}
.product-btn a {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.products .product:hover .search-btn,
.products .product:hover .favourite-btn {
}
.products .product:hover .add-to-cart-btn {
  transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  border-left: 1px solid rgba(246, 246, 246, 0.18);
  border-right: 1px solid rgba(246, 246, 246, 0.18);
}
.products .product:hover .favourite-btn {
  transition-delay: 200ms;
  -webkit-transition-delay: 200ms;
  -moz-transition-delay: 200ms;
  -o-transition-delay: 200ms;
}
.products .product .ttm-product-box .ttm-product-content {
  text-align: left;
  padding: 18px 0 15px;
  position: relative;
}
.products .product .ttm-product-box .ttm-product-content .ttm-product-title h2 {
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 17px;
  padding-bottom: 0;
  line-height: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.products .product .price {
  margin: 0;
  min-height: 20px;
  font-size: 15px;
  margin-top: 2px;
}
.products .price .product-Price-amount {
  font-size: 18px;
  font-weight: 400;
}
.products .price del .product-Price-amount {
  font-size: 13px;
  text-decoration: line-through;
  color: #8d8d8d;
}
.products .price ins {
  text-decoration: none;
  padding: 2px;
}
.products .star-ratings ul {
  padding: 0;
  margin: 0;
}
.products .star-ratings ul li {
  display: inline-block;
}
.products .star-ratings ul li i {
  font-size: 10px;
}

.products .product .onsale {
  background: #51ad00;
  color: #fff;
  padding: 0 10px;
  display: table;
  position: absolute;
  text-align: center;
  top: 25px;
  right: 0;
  min-width: 50px;
  line-height: 26px;
  z-index: 5;
  min-height: auto;
  height: 26px;
  width: 60px;
  vertical-align: top;
  border-radius: 0;
  padding-right: 20px;
}

/* product-poster */
.product-poster-content .ttm-bg-highlight-dark,
.product-poster-content .ttm-bg-highlight-skin {
  display: inline-block;
}
.product-poster-content .poster-label-tag {
  padding: 0 28px;
}
.product-poster-content .poster-label-tag {
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}
.product-poster-content .poster-label h2 {
  padding: 22px 42px;
}
.product-poster-content .poster-label h2 {
  text-align: center;
  font-size: 72px;
  line-height: 70px;
  color: #ffffff;
  font-weight: 600;
}
.product-poster-content .poster-text {
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  color: #494e54;
  font-weight: 400;
  margin-bottom: 30px;
  padding-top: 22px;
}
.product-poster-content .poster-amount h4 {
  text-align: left;
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
}

/* single-product-details */
.ttm-single-product-details {
  background-color: #fff;
  margin-bottom: 30px;
}
.ttm-single-product-info {
  padding-bottom: 30px;
}
div.product.ttm-single-product-details div.images {
  width: 48%;
  float: left;
}
div.product.ttm-single-product-details div.summary {
  width: 48%;
  float: right;
}
div.product.ttm-single-product-details div.summary .singel_product_title {
  padding-bottom: 8px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 25px;
  font-size: 28px;
}
div.product.ttm-single-product-details .product-gallery__image img {
  height: auto;
  max-width: 100%;
}
.ttm-single-product-details div.images .product-gallery__image:nth-child(n + 2) {
  width: 25%;
  display: inline-block;
  margin-top: 5px;
  padding: 3px;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
div.product .summary .price .Price-amount {
  font-size: 22px;
}
div.product .summary .product-rating {
  float: right;
  margin-bottom: 0px;
}
.summary .star-rating {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  line-height: 1;
}
.summary .star-rating li {
  display: inline-block;
  font-size: 12px;
}
.add-to-wishlist ul {
  padding: 0;
}
.add-to-wishlist ul li {
  display: inline-block;
  text-transform: capitalize;
}
.add-to-wishlist ul li:not(:last-child) {
  margin-right: 20px;
}
.add-to-wishlist ul li i {
  margin-right: 8px;
}
.add-to-wishlist ul li .spin-img {
  display: none;
}
.product_meta {
  padding-top: 17px;
  border-top: 1px solid #ddd;
  margin-top: 15px;
}
.ttm-single-product-details .product-details__short-description {
  margin-top: 20px;
  margin-bottom: 50px;
}
.summary form.cart {
  margin-top: 30px;
  margin-bottom: 22px;
}
div.product form.cart div.quantity {
  float: left;
  margin: 0 8px 0 0;
}
.quantity .qty {
  height: 41px;
  width: 88px;
  text-align: center;
}
.sku_wrapper {
  margin-right: 10px;
}
.posted_in,
.sku_wrapper {
  font-weight: 600;
  display: block;
}
.posted_in a,
.sku_wrapper span {
  font-weight: 400;
}

.tabs-for-single-products.ttm-tabs {
  padding: 0 30px 30px 0px;
}
div.product .ttm-tabs .content-inner h2 {
  font-size: 23px;
  line-height: 28px;
}
div.product .ttm-tabs .content-tab {
  padding: 30px;
  width: 100%;
  margin-top: -1px;
  border: 1px solid #ececec;
}
.related.products {
  margin-top: 50px;
}
.related.products h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 15px;
}
.comment-form textarea,
.comment-form input[type='text'],
.comment-form input[type='email'],
.comment-form input[type='url'] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding-left: 0;
}
#reviews #comments ol {
  margin: 0;
  padding: 0;
}
#reviews #comments ol.commentlist li img.avatar {
  width: 65px;
}
#reviews #comments ol.commentlist li img.avatar {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  background: #ebe9eb;
  border: 1px solid #e4e1e3;
  margin: 0;
  box-shadow: none;
}
div.product #reviews .star-rating {
  margin: 0;
  margin-top: 7px;
  float: right;
}
#reviews #comments ol.commentlist li {
  padding: 0;
  margin: 0 0 20px;
  position: relative;
  border: 0;
}
#reviews #comments ol.commentlist li .comment-text {
  border: 1px solid #e4e1e3;
  padding: 1em 1em 0;
  border-radius: 0;
  margin-left: 80px;
}
#reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: 16px;
  color: #828c96;
  margin-bottom: 9px;
}
div.product .Reviews .star-rating {
  margin: 0;
  margin-top: 7px;
  float: right;
}
.comment-form-rating {
  padding-bottom: 20px;
}
.comment-form-rating .stars {
  margin-bottom: 15px;
}
#review_form_wrapper .comment-reply-title {
  font-size: 18px;
  line-height: 20px;
  color: #283d58;
}
#review_form_wrapper .comment-respond p {
  margin: 0 0 10px;
}
#reviews #review_form_wrapper select {
  width: 154px !important;
}
#reviews #comment {
  height: 75px;
}
#review_form_wrapper .comment-respond .form-submit {
  margin-top: 30px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
  width: 100%;
  margin: 0 0 1.75em;
}
table tbody tr,
table thead tr {
  border: 1px solid #e6e6e6;
}
table tbody th {
  border-right: 1px solid #e6e6e6;
}
table.shop_attributes th {
  width: 150px;
  font-weight: 700;
  padding: 8px;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  line-height: 1.5;
}
table.shop_attributes td {
  font-style: italic;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  padding-left: 10px;
  line-height: 1.5;
}
table.shop_attributes td p {
  margin: 0;
  padding: 8px 0;
}

/* Cart */
.ttm-cart-form {
  margin: 0;
  background-color: #fff;
}
.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 0px;
}
table.shop_table tbody tr,
table.shop_table thead tr {
  border: 1px solid #e6e6e6;
}
table.shop_table th {
  font-weight: 700;
  padding: 9px 12px;
  line-height: 1.5em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
table.shop_table td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  vertical-align: middle;
  line-height: 1.5em;
}
table.shop_table .product-thumbnail img {
  width: 32px;
  height: auto;
}
table.shop_table td.actions {
  text-align: right;
}
table.shop_table td.actions .input-text {
  width: 150px;
  float: left;
}
.quantity {
  height: 41px;
  width: 88px;
}
.coupon {
  float: left;
}
.coupon .input-text {
  float: left;
  margin: 0 4px 0 0;
}
.actions button,
.coupon button,
a.checkout-button {
  border: 0;
}
.coupon button,
a.checkout-button,
button.cart_button {
  padding: 15px 30px 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  text-align: center;
}
button.cart_button {
  padding: 11px 40px;
}
.cart-collaterals {
  margin-top: 60px;
}
.cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}
.cart_totals h2 {
  margin-bottom: 15px;
  font-size: 28px;
}
a.checkout-button {
  display: block;
}
a.remove {
  display: block;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red;
  text-decoration: none;
  font-weight: 700;
  border: 0;
}
a.remove:hover {
  color: #fff;
  background: red;
}

/* checkout */
.form-row .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
}
form .form-row {
  padding: 3px;
  margin: 0 0 6px;
}
.checkout h3#order_review_heading {
  margin-bottom: 30px;
  margin-top: 15px;
}
.checkout #payment {
  background: #f5faff;
  border-radius: 5px;
}
ul.payment_methods {
  text-align: left;
  padding: 1em;
  border-bottom: 1px solid #d3ced2;
  margin: 0;
  list-style: none outside;
}
#payment .payment_box {
  border-top: 3px solid #a46497;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #dfdcde;
  color: #515151;
  padding-left: 50px;
}
#payment div.form-row {
  padding: 1em;
}
.checkout-process-div {
  margin: 16px 0;
  overflow: hidden;
}
.checkout-button-separator {
  display: block;
  opacity: 0.5;
  margin: 0 0 16px;
}
.coupon_toggle .coupon_code {
  padding: 1em 2em 1em 3.5em;
  margin: 0 0 2em;
  position: relative;
  border-top: 3px solid;
  background-color: #f8f9fa;
}
.coupon_toggle .coupon_code:before,
#payment .payment_box:before {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 1.5em;
  content: '\e67c';
  font-family: 'themify';
}
.checkout-form input[type='text'],
.checkout-form input[type='tel'],
.checkout-form input[type='email'] {
  line-height: normal;
}
.checkout .billing-fields h3,
.checkout .additional-fields h3 {
  font-size: 28px;
}
.checkout-form.checkout-form-row-first,
.checkout-form.checkout-form-row-last {
  width: 47%;
  overflow: visible;
}
.checkout-form.checkout-form-row-first {
  float: left;
}
.checkout-form.checkout-form-row-last {
  float: right;
}
.checkout-form .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
}
#payment div.checkout-form {
  padding: 1em;
}
.checkout .checkout-form-row-first,
.checkout .checkout-form-row-last {
  width: 47%;
  overflow: visible;
}
.checkout .checkout-form-row-first {
  float: left;
}
.checkout .checkout-form-row-last {
  float: right;
}

/* ===============================================
    25.panel
------------------------*/
.ttm-panel {
  padding: 0 15px;
}
.panel-row {
  display: none;
}
.panel-heading {
  margin-bottom: 10px;
  border-color: #f0f0f0;
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  -webkit-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}
.panel-title {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}
.panel-title > a {
  background: 0 0;
  display: block;
  padding: 14px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  position: relative;
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-title > a span {
  padding-left: 12px;
}
