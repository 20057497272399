/**
  * Name: Fondex
  * Version: 1.3
  * Author: ThemetechMount
  * Author URI: http://www.themetechmount.com
*/

@import url('https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

/**

  1. General
     - Variables
     - Transition
     - Shadow
     - Extra-outer

  2. Spacing
     - Padding
     - Margin

  3. Color
     - Skin-color, Skin-bg-color, Skin-border-color
     - darkGrey-color, darkGrey-bg-color
     - white-color, white-bg-color, white-border-color
     - Grey-color, Grey-bg-color, grey-border-color
     - Extra-color

  4. Pre-loader

  5. TooltipTop

  6. TopBar

  7. Header
     - SiteBrand(logo)
     - SiteNavigation(Menu)
     - ttm-rt-contact(HeaderIcons - Search, Cart)
     - ttm-search-overlay

  8. Footer
     - FirstFooter
     - SecondFooter
     - menu-footer-services
     - mailchimp-inputbox
     - flicker-widget
     - Footer-nav-menu

  9. GoTop BUtton

  10. Page-Title-Row

  12. Inner-Pages
      - contact-us
      - error
      - portfolio
      - blog
      - team-member-details
      - element

**/

/* ===============================================
    General
------------------------*/

/** Variables **/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a:focus {
  text-decoration: none;
}
a:focus,
a:hover {
  color: #25c9d1;
  text-decoration: none;
}
a,
button,
input {
  outline: medium none;
  color: #25c9d1;
}
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
strong {
  font-weight: 700;
}
iframe {
  width: 100%;
  border: none;
  display: block;
}
p {
  margin: 0 0 15px;
}

*::-moz-selection {
  background: #25c9d1;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #25c9d1;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #25c9d1;
  color: #fff;
  text-shadow: none;
}

textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input,
select {
  font-family: inherit;
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 0;
  vertical-align: middle;
  width: 100%;
  color: #5d6576;
  padding: 9px 15px;
  font-weight: 400;
  background-color: #fff;
  text-transform: inherit;
  border: 1px solid #dedede;
  font-size: 14px;
  outline: none;
  line-height: inherit;
}
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  display: inline-block;
  text-decoration: none;
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;
  padding: 11px 40px 11px;
  border: 2px solid transparent;
  border-radius: 0;
  color: #fff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  border: 1px solid #fda02b;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder,
input::-ms-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholde {
  color: #fff;
}

menu,
ol,
ul {
  margin: 0;
  padding: 0 0 0 25px;
}

body {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #8d9297;
  background-color: #f3f7f9;
  letter-spacing: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
  font-weight: 600;
  color: #0b0c26;
}
h1 {
  font-size: 45px;
  line-height: 50px;
  text-transform: uppercase;
}
h2 {
  font-size: 35px;
  line-height: 40px;
}
h3 {
  font-size: 30px;
  line-height: 35px;
}
h4 {
  font-size: 24px;
  line-height: 30px;
}
h5 {
  font-size: 20px;
  line-height: 25px;
}
h6 {
  font-size: 16px;
  line-height: 18px;
}

h1-w,
h2-w,
h3-w,
h4-w,
h5-w,
h6-w {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
  font-weight: 600;
  color: #fff;
}
h1-w {
  font-size: 45px;
  line-height: 50px;
  text-transform: uppercase;
}
h2-w {
  font-size: 35px;
  line-height: 40px;
}
h3-w {
  font-size: 30px;
  line-height: 35px;
}
h4-w {
  font-size: 24px;
  line-height: 30px;
}
h5-w {
  font-size: 20px;
  line-height: 25px;
}
h6-w {
  font-size: 16px;
  line-height: 18px;
}

/** Transition **/

.featured-icon-box .featured-icon,
.ttm-btn,
button,
.owl-item,
img,
span,
.featured-icon-box,
.featured-icon,
#totop {
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
a,
.site-branding,
#site-header-menu #site-navigation .menu ul li > a,
.ttm-header-icon,
.header-btn,
a.ttm-btn,
i.ttm-btn,
a.nav-link,
.social-icons li > a,
.ttm-header-style-infostack .ttm-custombutton a,
.ttm-menu-toggle .toggle-block,
.ttm-header-style-infostack .ttm-header-icons .ttm-header-search-link a,
.accordion .toggle-title a,
.widget-download ul.download li {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.tp-caption:hover,
.featured-imagebox-portfolio .featured-content,
.ttm-processbox-wrapper .featured-content,
.ttm-processbox-wrapper .ttm-processbox .process-num,
.featured-icon-box.style10 {
  -webkit-transition: all 0.3s ease-in-out 0s !important;
  -moz-transition: all 0.3s ease-in-out 0s !important;
  -ms-transition: all 0.3s ease-in-out 0s !important;
  -o-transition: all 0.3s ease-in-out 0s !important;
  transition: all 0.3s ease-in-out 0s !important;
}
.ttm-btn span,
.nav-link span,
.cart-info a i,
.site-branding img,
.site-branding a,
.panel-title > a span,
.ttm-header-icon.ttm-header-cart-link .navbar-form span,
ul.products li.product .ttm-shop-icon a i {
  -webkit-transition: all 0s ease-in-out 0s;
  -moz-transition: all 0s ease-in-out 0s;
  -ms-transition: all 0s ease-in-out 0s;
  -o-transition: all 0s ease-in-out 0s;
  transition: all 0s ease-in-out 0s;
}
.featured-imagebox-team .featured-thumbnail img {
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.featured-imagebox .ttm-box-bottom-content,
.ttm-box-bottom-content .ttm-icon,
.ttm-box-bottom-content .featured-title h5,
.ttm-box-bottom-content .featured-desc {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
/**  Shadow **/
.box-shadow {
  -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  -moz-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}
.box-shadow2 {
  -webkit-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  -moz-box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
  box-shadow: 0 0 15px 0 rgba(32, 46, 60, 0.12);
}

/** Extra-outer **/
body .page {
  overflow: hidden;
  position: relative;
  z-index: 10;
}
body .site-main {
  padding-top: 82px;
  background-color: #fff;
  position: relative;
  z-index: 9;
}
body .site-main > .ttm-row:first-child {
  margin-top: -120px;
}

.margin-top-bascule {
  margin-top: -40px;
}

body .page.sidebar-true .site-main {
  padding: 0;
  background-color: #fff;
}

.container-fluid {
  padding: 0 0;
  overflow: hidden;
}

a {
  color: #0b0c26;
}

.alignleft {
  float: left;
  margin: 6px 28px 8px 0;
}

.width-30 {
  width: 30%;
}
.width-36 {
  width: 36%;
}
.width-64 {
  width: 64%;
}
.width-70 {
  width: 70%;
}
.width-100 {
  width: 100%;
}

.font-sz-12 {
  font-size: 12px;
}
.font-sz-27 {
  font-size: 27px;
}
.font-w-500 {
  font-weight: 500;
}

.z-1 {
  z-index: 1 !important;
}

.ttm_single_image-wrapper.with-border {
  border: 1px solid #e8ecef;
}
.ttm_single_image-wrapper.w100 img {
  width: 100%;
}
.ttm_single_image-wrapper.with-top-border img {
  border-top: 12px solid;
}
.ttm_single_image-wrapper.with-border15 img {
  border: 15px solid;
}
/* ===============================================
    Spacing
------------------------*/

/** Padding **/
.spacing-1 {
  margin-top: -60px;
  margin-bottom: -66px;
  padding-top: 50px;
  padding-left: 55px;
  padding-bottom: 50px;
  padding-right: 15px;
}
.spacing-2 {
  padding: 90px 0;
}
.spacing-3 {
  padding-top: 75px !important;
  padding-right: 186px !important;
  padding-bottom: 53px !important;
}
.spacing-4 {
  margin-left: -90px;
  margin-top: -55px;
}
.spacing-5 {
  margin-left: 50px;
  margin-right: -50px;
  margin-top: -60px;
}
.spacing-6 {
  padding-right: 125px !important;
  padding-bottom: 0px !important;
  padding-top: 45px !important;
}
.spacing-7 {
  margin-top: -177px !important;
  margin-left: -30px !important;
  padding-top: 60px !important;
  padding-right: 65px !important;
  padding-bottom: 50px !important;
  padding-left: 50px !important;
}

.p-12 {
  padding: 12px !important;
}

.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-45 {
  padding-right: 45px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-55 {
  padding-right: 55px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-75 {
  padding-right: 75px !important;
}
.pr-80 {
  padding-right: 80px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-35 {
  padding-left: 35px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pl-55 {
  padding-left: 55px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pl-65 {
  padding-left: 65px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-75 {
  padding-left: 75px !important;
}
.pl-80 {
  padding-left: 80px !important;
}

.pt-2 {
  padding-top: 2px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-18 {
  padding-top: 18px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-35 {
  padding-top: 35px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-55 {
  padding-top: 55px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-65 {
  padding-top: 65px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-75 {
  padding-top: 75px !important;
}
.pt-80 {
  padding-top: 80px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-55 {
  padding-bottom: 55px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}

.ptb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
/** Margin **/
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-23 {
  margin-top: 23px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-37 {
  margin-top: 37px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-45 {
  margin-top: 45px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-100 {
  margin-top: 100px !important;
}

.mt_5 {
  margin-top: -5px !important;
}
.mt_10 {
  margin-top: -10px !important;
}
.mt_12 {
  margin-top: -12px !important;
}
.mt_15 {
  margin-top: -15px !important;
}
.mt_25 {
  margin-top: -25px !important;
}
.mt_30 {
  margin-top: -30px !important;
}
.mt_40 {
  margin-top: -40px !important;
}
.mt_50 {
  margin-top: -50px !important;
}
.mt_55 {
  margin-top: -55px !important;
}
.mt_60 {
  margin-top: -60px !important;
}
.mt_70 {
  margin-top: -70px !important;
}
.mt_72 {
  margin-top: -72px !important;
}
.mt_80 {
  margin-top: -80px !important;
}
.mt_86 {
  margin-top: -86px !important;
}
.mt_120 {
  margin-top: -120px !important;
}
.mt_404 {
  margin-top: -404px !important;
}
.mt_405 {
  margin-top: -405px !important;
}

.ml-0 {
  margin-left: 0px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-35 {
  margin-left: 35px !important;
}
.ml-65 {
  margin-left: 65px !important;
}
.ml-287 {
  margin-left: 287px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.ml_30 {
  margin-left: -30px !important;
}
.ml_40 {
  margin-left: -40px !important;
}
.ml_60 {
  margin-left: -60px !important;
}
.ml_80 {
  margin-left: -80px !important;
}
.ml_90 {
  margin-left: -90px !important;
}

.mr-0 {
  margin-right: 0px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr_40 {
  margin-right: -40px;
}
.mr_60 {
  margin-right: -60px;
}
.mr_80 {
  margin-right: -80px;
}

.mb_1 {
  margin-bottom: -1px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mb-27 {
  margin-bottom: 27px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-45 {
  margin-bottom: 45px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-55 {
  margin-bottom: 55px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mb_30 {
  margin-bottom: -30px !important;
}
.mb_60 {
  margin-bottom: -60px !important;
}
.mb_80 {
  margin-bottom: -80px !important;
}

/* ===============================================
    Color
------------------------*/
/** 1.Skin-color **/
a:hover,
.ttm-textcolor-skincolor,
.ttm-textcolor-white a:hover,
.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-color-skincolor,
.ttm-btn.ttm-btn-color-darkgrey.btn-inline:hover,
.ttm-topbar-wrapper.ttm-textcolor-white a:hover,
a.tp-caption.dark-text-button:hover,
.section-title h5,
.ttm-bgcolor-white .section-title h5,
.ttm-textcolor-white.ttm-topbar-wrapper .social-icons li > a:hover,
ul li .icon-box.icon-style-border i,
.ttm-header-style-stackcenter .info-widget-content h2,
#site-header-menu #site-navigation .menu ul.dropdown > li.active > a,
#site-header-menu #site-navigation .menu ul.sub-menu > li.active > a,
#site-header-menu #site-navigation .menu > ul > li > ul > li a:before,
.ttm-header-style-overlay #site-header-menu #site-navigation .menu ul.dropdown > li.active > a,
.ttm-header-style-overlay #site-header-menu #site-navigation .menu ul.dropdown > li:hover > a,
#site-header-menu #site-navigation .menu ul > li:hover > a,
#site-header-menu #site-navigation .menu ul.sub-menu li > a:hover,
#site-navigation .menu ul > li:hover > a:before,
.ttm-icon_element-color-skincolor,
.ttm-btn.ttm-btn-color-white:hover,
.ttm-team-details-list .ttm-team-list-title i,
.ttm-fid-icon-wrapper i,
.ttm-bgcolor-darkgrey .featured-title h6,
.breadcrumb-wrapper span:last-child,
.tp-caption.skin-flatborder-button,
.ttm-btn.btn-inline.ttm-btn-color-black:hover,
.featured-imagebox-portfolio .featured-iconbox.ttm-media-link a:hover,
.featured-imagebox-team .ttm-social-links-wrapper ul li a:hover,
ul.ttm-our-location-list li i,
.ttm-ptablebox-price-w,
.ttm-pricing-plan .ttm-ptablebox-frequency,
.sidebar ul.contact-widget-wrapper li i,
.accordion.style2 .ttm-style-classic.ttm-toggle-title-border.active .toggle-title a,
.sidebar .widget a:hover,
.products .product .ttm-product-box .ttm-product-content .ttm-product-title h2:hover,
#payment .payment_box:before,
.coupon_toggle .coupon_code:before,
.featured-imagebox-portfolio-style1 .featured-title h5 a:hover,
.ttm-blog-classic .ttm-blog-classic-content blockquote:before {
  color: #25c9d1;
}

h1.ttm-textcolor-skincolor,
h2.ttm-textcolor-skincolor,
h3.ttm-textcolor-skincolor,
h4.ttm-textcolor-skincolor,
h5.ttm-textcolor-skincolor,
h6.ttm-textcolor-skincolor {
  color: #25c9d1 !important;
}

/** Skin-bg-color **/
.ttm-bgcolor-skincolor,
.ttm-bgcolor-skincolor > .ttm-bg-layer,
.ttm-bgcolor-skincolor > .ttm-bg-layer > .ttm-bg-layer-inner,
.tp-shapewrapper,
.ttm-bg-highlight-skin,
.tp-caption.skin-flatborder-button:hover,
.top-contact.ttm-highlight-left:after,
.top-contact.ttm-highlight-right:after,
.ttm-header-icons .ttm-header-cart-link span.number-cart,
.ttm-header-icons .ttm-header-search-link a:hover,
#site-navigation .menu ul.dropdown > li > a:before,
#site-header-menu #site-navigation .menu ul li a span,
.ttm-header-style-infostack #site-header-menu #site-navigation .menu > ul > li > a:before,
.ttm-icon_element-background-color-skincolor,
.ttm-btn-bgcolor-skincolor,
.ttm-tabs ul.tabs li.active a,
.newsletter-form input[type='submit'],
.ttm-quote-form input[type='submit'],
.widget .widget-title:after,
.social-icons.circle li > a:hover,
.ttm-btn-bgcolor-black:hover,
.ttm-btn.btn-inline:hover:after,
.ttm-btn.ttm-btn-color-skincolor.ttm-btn-style-border:hover,
.ttm-btn.ttm-btn-bgcolor-darkgrey:hover,
#totop.top-visible,
.ttm-processbox-wrapper .ttm-processbox:hover .process-num,
.ttm-video-btn .ttm-btn-play,
.ttm-tabs.ttm-tab-style-horizontal ul.tabs li.active a,
.ttm-tabs.tabs-for-single-products ul.tabs li:hover a,
.widget.widget-nav-menu ul li a:after,
.mailchimp-inputbox button[type='submit'],
a.checkout-button:hover,
.coupon button:hover,
.ttm-cart-form button:hover,
.place-order button:hover,
.ttm-btn-play:after,
.ttm-btn-play:before,
.tp-caption.skin-flat-button,
.skin-flat-button,
.ttm-video-play-btn,
.ttm-video-btn .ttm-video-btn-play,
.ttm-video-btn .ttm-video-btn-play:before,
.ttm-video-btn .ttm-video-btn-play:after,
.ttm-tabs.ttm-tab-style-classic ul.tabs li.active:after,
.tribe-events-back a,
.tribe-events-cal-links a,
.tribe-events-nav-next a,
.ttm-btn-play:after,
.ttm-btn-play:before,
.ttm-search-overlay .ttm-site-searchform button,
.featured-imagebox-services:hover .ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white,
.section-title.with-desc .title-header:before,
.featured-imagebox-team .ttm-social-links-wrapper ul li a,
.featured-imagebox:hover .ttm-box-bottom-content,
.featured-imagebox-portfolio:hover .featured-content,
.featured-imagebox-portfolio .featured-iconbox.ttm-media-link a,
.ttm-processbox-wrapper .ttm-processbox .process-num,
.ttm-bgcolor-skincolor .panel-title > a:hover,
.ttm-bgcolor-skincolor .panel-title > a.active,
.ttm-rt-contact .ttm-custombutton,
.sidebar .widget .tagcloud a:hover,
.accordion .ttm-style-classic.ttm-toggle-title-border.active .toggle-title,
.ttm-shop-icon .product-btn:hover,
.widget-top-rated-products .Price-amount.amount ins,
.ttm-pagination .page-numbers.current,
.ttm-pagination .page-numbers:hover,
.ttm-tabs.style2 ul.tabs li a:before,
.ttm-nextprev-bottom-nav .nav-previous a,
.ttm-box-view-left-image .featured-content .category > a:hover,
.featured-imagebox.ttm-box-view-left-image .featured-content .category > a:hover,
.ttm-tag-list span a:hover,
.comment-list a.comment-reply-link:hover,
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li.active a,
.services-slide.owl-theme .owl-controls .owl-nav div:hover,
.featured-imagebox-team.style2:hover .ttm-team-box-view-overlay,
.color-trigger {
  background-color: #25c9d1;
}

/** Skin-border-color **/
.ttm-btn.ttm-btn-color-skincolor.ttm-btn-style-border,
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-skincolor,
.newsletter-form input[type='email']:focus,
.social-icons.circle li > a:hover,
.accordion .ttm-style-classic.ttm-toggle-title-border.active .toggle-title,
.ttm-pf-single-content-wrapper-innerbox.ttm-pf-view-top-image
  .ttm-pf-single-detail-box
  .ttm-pf-detailbox,
.sidebar .widget .tagcloud a:hover,
.tp-caption.skin-flat-button,
.skin-flat-button,
.tp-caption.skin-flatborder-button,
textarea:focus,
select:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.ttm-pricing-plan:hover,
.ttm-pricing-plan.box-shadow2,
.ttm-processbox-wrapper .ttm-processbox:hover .featured-content,
.featured-imagebox-services:hover .ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white,
.ttm-video-btn .ttm-btn-play,
.ttm-btn-play:after,
.ttm-btn-play:before,
.skin-border .with-top-border img,
.ttm-bgcolor-skincolor .panel-title > a,
.featured-icon-box.style3:after,
.featured-icon-box.style3:before,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-search-link a:hover,
.ttm-tabs ul.tabs li.active a,
.ttm-blog-classic .ttm-blog-classic-content blockquote,
.ttm-header-icons .ttm-header-search-link a:hover {
  border-color: #25c9d1 !important;
  box-shadow: none !important;
}
.ttm-search-overlay,
#site-header-menu #site-navigation .menu > ul,
#site-header-menu #site-navigation .menu > ul > li > ul,
.ttm-rounded-shadow-box,
div.product ul.tabs li.active a:before,
.coupon_toggle .coupon_code,
#payment .payment_box {
  border-top-color: #25c9d1 !important;
}
.featured-imagebox-portfolio.ttm-box-view-top-image:hover .ttm-box-bottom-content:before {
  border-color: transparent transparent #25c9d1 transparent;
}
.ttm-search-overlay .w-search-form-row:before,
.testimonials.style2 .testimonial-content {
  border-bottom-color: #25c9d1 !important;
}

/** 2.DarkGrey-color **/
.ttm-textcolor-darkgrey h1,
.ttm-textcolor-darkgrey h2,
.ttm-textcolor-darkgrey h3,
.ttm-textcolor-darkgrey h4,
.ttm-textcolor-darkgrey h5,
.ttm-textcolor-darkgrey h6,
.ttm-bgcolor-white .section-title h2.title,
.ttm-btn.ttm-btn-color-white.ttm-btn-style-border:hover,
.ttm-bgcolor-skincolor .ttm-btn.ttm-btn-bgcolor-darkgrey:hover,
.ttm-btn.ttm-btn-color-darkgrey,
a.tp-caption.dark-text-button,
.tp-caption.white-flatborder-button:hover,
#site-header-menu #site-navigation .menu ul li > a,
.ttm-header-style-stackcenter .info-widget-content p,
.ttm-btn.ttm-btn-color-black,
.ttm-icon_element-color-darkgrey i,
.featured-imagebox .ttm-box-bottom-content .ttm-btn.btn-inline:hover:after,
.featured-imagebox-portfolio .featured-content a,
.ttm-bgcolor-skincolor .panel-title > a,
.sidebar .widget a,
.ttm-pagination .page-numbers,
.products .price .product-Price-amount,
.ttm-tabs.style2 ul.tabs li.active a,
.ttm-tabs.style2 ul.tabs li:hover a,
.ttm-tabs ul.tabs li a,
.social-icons.circle li > a,
.ttm-box-view-left-image .featured-content .category > a,
.featured-imagebox.ttm-box-view-left-image .featured-content .category > a,
.ttm-team-details-list .ttm-team-list-title,
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li a,
.ttm-bgcolor-skincolor .testimonials.style2 .testimonial-content blockquote,
.ttm-bgcolor-skincolor .testimonials.style2 .testimonial-content h6 {
  color: #0b0c26;
}

.ttm-pagination .page-numbers {
  cursor: pointer;
}

/** DarkGrey-bg-color **/
.ttm-bgcolor-darkgrey,
.ttm-bgcolor-darkgrey > .ttm-bg-layer,
.ttm-bgcolor-darkgrey > .ttm-bg-layer > .ttm-bg-layer-inner,
.ttm-btn-bgcolor-black,
.ttm-bg-highlight-dark,
.dark-flatborder-button:hover,
.ttm-video-play-btn:hover,
.ttm-header-icons .ttm-header-cart-link a:hover,
.ttm-header-style-overlay .ttm-stickable-header.fixed-header,
.ttm-btn.ttm-btn-color-black.ttm-btn-style-border:hover,
.newsletter-form input[type='submit']:hover,
.ttm-quote-form input[type='submit']:hover,
.ttm-icon_element-background-color-darkgrey,
.ttm-btn-bgcolor-white:hover,
.ttm-btn-bgcolor-skincolor:hover,
.ttm-btn-bgcolor-grey:hover,
.tp-caption.black-flatborder-button:hover,
.tp-caption.skin-flat-button:hover,
.testimonials.style1 .testimonial-content .testimonial-avatar:after,
.widget.widget-nav-menu ul li:hover a:before,
.widget.widget-nav-menu ul li.active a:before,
.ttm-nextprev-bottom-nav .nav-next a:hover,
.ttm-nextprev-bottom-nav .nav-previous a:hover,
.ttm-tabs.ttm-tab-style-horizontal ul.tabs li a,
.ttm-btn.ttm-btn-bgcolor-darkgrey,
.ttm-btn.btn-inline:before,
.ttm-btn.btn-inline:after,
.ttm-processbox-wrapper .ttm-processbox:hover .process-num,
.ttm-bgcolor-skincolor .featured-imagebox-portfolio:hover .featured-content,
.featured-imagebox .ttm-box-bottom-content .ttm-btn.btn-inline:hover:after,
a.checkout-button,
.coupon button,
.ttm-cart-form button,
.place-order button,
.widget-download ul.download li:hover,
.ttm-shop-icon .product-btn,
.ttm-tabs.style2 ul.tabs li a,
.comment-list a.comment-reply-link,
.featured-imagebox-team.featured-imagebox-team-details .ttm-social-links-wrapper ul li a:hover {
  background-color: #0b0c26;
}
.sidebar .widget .tagcloud a,
.tp-caption.black-flatborder-button:hover,
.tp-caption.skin-flat-button:hover,
.darkgrey-border .with-top-border,
.ttm-btn.ttm-btn-color-black.ttm-btn-style-border:hover,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-cart-link a:hover,
.featured-imagebox-team.featured-imagebox-team-details .featured-content-team,
.ttm-header-icons .ttm-header-cart-link a:hover {
  border-color: #0b0c26 !important;
}
.ttm-bgcolor-skincolor .testimonials.style2 .testimonial-content {
  border-bottom-color: #0b0c26 !important;
}
/** 3.White-color **/
.ttm-textcolor-white h1,
.ttm-textcolor-white h2,
.ttm-textcolor-white h3,
.ttm-textcolor-white h4,
.ttm-textcolor-white h5,
.ttm-textcolor-white h6,
.ttm-bgcolor-skincolor a,
.ttm-textcolor-white a,
.skin-flatborder-button:hover,
.dark-flatborder-button:hover,
.ttm-bg-highlight-dark,
.ttm-bg-highlight-skin,
#site-header-menu #site-navigation .menu ul li a span,
.ttm-header-style-classicinfo .ttm-header-icons .ttm-header-search-link a:hover,
.ttm-header-style-classic .ttm-header-icons .ttm-header-search-link a:hover,
.ttm-header-style-infostack .ttm-header-icons .ttm-header-search-link a:hover,
.ttm-header-style-infostack .ttm-header-icons .ttm-header-cart-link a:hover,
.ttm-header-icons .ttm-header-search-link a:hover,
.ttm-header-icons .ttm-header-cart-link a:hover,
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white,
.ttm-textcolor-white .ttm-fid-icon-wrapper i,
.ttm-textcolor-white .featured-content .featured-title h5 a,
.ttm-textcolor-white .ttm-social-links-wrapper ul li a,
.ttm-textcolor-white .ttm-social-links-wrapper ul li a:hover,
.footer .social-icons li > a:hover,
.ttm-btn-bgcolor-white:hover,
.tp-caption.black-flatborder-button:hover,
.ttm-btn.ttm-btn-color-white.ttm-btn-bgcolor-white:hover,
.ttm-btn.ttm-btn-color-white.ttm-btn-bgcolor-skincolor:hover,
.ttm-btn.ttm-btn-color-skincolor.ttm-btn-bgcolor-white:hover,
.ttm-btn.ttm-btn-color-skincolor.ttm-btn-bgcolor-skincolor:hover,
.ttm-btn.ttm-btn-bgcolor-grey:hover,
.ttm-btn.ttm-btn-color-skincolor.ttm-btn-style-border:hover,
.ttm-btn-bgcolor-black,
a.ttm-btn-bgcolor-black,
.ttm-btn-bgcolor-black:hover,
a.ttm-btn-bgcolor-black:hover,
.ttm-btn-bgcolor-skincolor,
a.ttm-btn-bgcolor-skincolor,
.ttm-btn-bgcolor-skincolor:hover,
a.ttm-btn-bgcolor-skincolor:hover,
.accordion .ttm-style-classic.ttm-toggle-title-border.active .toggle-title a,
.widget.widget-nav-menu ul li:hover a,
.widget.widget-nav-menu ul li.active a,
.sidebar .widget_product_categories li span,
.sidebar .widget-Categories li span,
.ttm-pagination .page-numbers.current,
.sidebar .widget .tagcloud a:hover,
.ttm-pagination .page-numbers:hover,
.ttm-box-view-left-image .featured-content .category > a:hover,
.single .ttm-team-member-view-default .ttm-social-links-wrapper ul li a:hover,
.featured-imagebox-services:hover .ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white,
.ttm-bgcolor-skincolor .testimonials .testimonial-content blockquote,
a.checkout-button,
a.checkout-button:hover,
.coupon button,
.ttm-cart-form button,
.place-order button {
  color: rgba(255, 255, 255, 1);
}
.ttm-textcolor-white .text-opecity,
.ttm-textcolor-white.text-opecity,
.ttm-header-icons .ttm-header-cart-link a,
.ttm-header-icons .ttm-header-search-link a,
.ttm-bgcolor-darkgrey .timeline .timeline-body p,
.ttm-bgcolor-skincolor .timeline .timeline-body p,
.ttm-bgcolor-skincolor .featured-icon-box p,
.ttm-bgcolor-darkgrey .featured-icon-box p,
.ttm-bgcolor-darkgrey p,
.ttm-bgcolor-skincolor h6 {
  color: rgba(255, 255, 255, 0.75);
}
.ttm-bgcolor-skincolor .testimonials .testimonial-content label,
.ttm-bgcolor-darkgrey .testimonials .testimonial-content label,
.ttm-textcolor-white .testimonials .testimonial-content label,
.ttm-bgcolor-skincolor > p,
.ttm-bgcolor-skincolor p,
.ttm-bgcolor-darkgrey > p,
.ttm-bgcolor-skincolor input::placeholder,
.ttm-bgcolor-skincolor select::placeholder,
.ttm-bgcolor-skincolor textarea::placeholder,
.ttm-bgcolor-skincolor a:hover,
.ttm-bgcolor-skincolor ul.top-contact li a:hover,
.ttm-bgcolor-skincolor.ttm-topbar-wrapper ul.social-icons li > a:hover,
.ttm-bgcolor-skincolor .section-title .title-desc,
.ttm-bgcolor-darkgrey .section-title .title-desc,
.featured-icon-box.style6 .featured-content .featured-desc p {
  color: rgba(255, 255, 255, 0.85);
}

.featured-desc-w p {
  color: #fff;
}

.ttm-topbar-wrapper.ttm-textcolor-white,
.ttm-topbar-wrapper.ttm-textcolor-white a {
  color: rgba(255, 255, 255, 0.95);
}
.heilight-white-text,
.ttm-textcolor-white,
.ttm-textcolor-white a,
.ttm-btn.ttm-btn-color-white,
.ttm-highlight-right li,
.ttm-icon_element-color-white i,
.ttm-icon_element-background-color-skincolor i,
.ttm-icon_element-background-color-darkgrey i,
.ttm-bgcolor-skincolor .section-title h2.title,
.ttm-bgcolor-darkgrey .section-title h2.title,
.ttm-bgcolor-skincolor .section-title h5,
.ttm-bgcolor-skincolor .featured-title h5,
.ttm-bgcolor-darkgrey .featured-title h5,
.ttm-bgcolor-skincolor .testimonial-slide .testimonials .testimonial-content h6,
.ttm-bgcolor-darkgrey .testimonial-slide .testimonials .testimonial-content h6,
.ttm-bgcolor-skincolor .inside h3,
.ttm-bgcolor-darkgrey .inside h3,
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input input,
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input textarea,
.ttm-tabs ul.tabs li.active a,
.ttm-bgcolor-skincolor .panel-title > a:hover,
.ttm-bgcolor-skincolor .panel-title > a.active,
.ttm-btn.ttm-btn-bgcolor-darkgrey,
.ttm-bgcolor-skincolor .featured-title h6,
.ttm-bgcolor-darkgrey .ttm-progress-bar h4,
.ttm-bgcolor-skincolor .ttm-progress-bar h4,
.ttm-bgcolor-darkgrey .ttm-progress-bar .progress-parcent,
.ttm-bgcolor-skincolor .ttm-progress-bar .progress-parcent,
.tp-caption.skin-flatborder-button:hover,
.ttm-btn.ttm-btn-color-black.ttm-btn-style-border:hover,
.featured-imagebox-portfolio:hover .featured-content a,
.featured-imagebox-team .ttm-social-links-wrapper ul li a,
.featured-imagebox:hover .ttm-box-bottom-content .ttm-icon,
.featured-imagebox:hover .ttm-box-bottom-content .featured-title h5 a,
.featured-imagebox:hover .ttm-box-bottom-content .ttm-btn.ttm-btn-color-darkgrey,
.featured-imagebox:hover .ttm-box-bottom-content .featured-desc,
.featured-imagebox-portfolio-style1 .featured-title h5 a,
.ttm-rt-contact .ttm-custombutton a,
.ttm-rt-contact .ttm-custombutton a:hover,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-cart-link a,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-search-link a,
.widget-download ul.download li:hover a,
.widget-top-rated-products .Price-amount.amount ins,
.products .product .ttm-shop-icon a,
.ttm-tabs.style2 ul.tabs li a,
.footer .social-icons li > a,
.social-icons.circle li > a:hover,
.ttm-nextprev-bottom-nav .nav-previous a,
.featured-imagebox-team.featured-imagebox-team-details .ttm-social-links-wrapper ul li a:hover,
.ttm-tag-list span a:hover,
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li.active a,
.ttm-bgcolor-skincolor .ttm-fid-icon-wrapper i,
.ttm-bgcolor-skincolor .ttm-fid.inside h4,
.services-slide.owl-theme .owl-controls .owl-nav div.owl-prev:hover:before,
.services-slide.owl-theme .owl-controls .owl-nav div.owl-next:hover:before,
.featured-imagebox-team.style2 .featured-title h5 a,
.featured-imagebox-team.style2 .featured-content .category {
  color: #fff;
}

/** White-bg-color **/
.ttm-bgcolor-white,
.ttm-bgcolor-white > .ttm-bg-layer,
.ttm-bgcolor-white > .ttm-bg-layer > .ttm-bg-layer-inner,
.ttm-bgcolor-skincolor .section-title.with-desc .title-header:before,
.ttm-header-style-infostack .ttm-header-wrap,
.ttm-header-style-classic .ttm-header-wrap,
.ttm-header-style-stackcenter .ttm-header-wrap,
.ttm-bgcolor-skincolor .section-title .title-header:after,
.ttm-bgcolor-skincolor .ttm-btn.ttm-btn-bgcolor-darkgrey:hover,
.ttm-btn.ttm-btn-style-border.ttm-bgcolor-white,
.ttm-btn.ttm-btn-color-white.ttm-btn-style-border:hover,
.ttm-bgcolor-darkgrey .ttm-custombutton.ttm-btn-bgcolor-skincolor:hover,
.featured-imagebox .ttm-box-bottom-content,
.ttm-btn-bgcolor-white,
.ttm-col-bgcolor-skincolor .inside.ttm-fid-view-topicon h3:after,
.ttm-bgcolor-skincolor .inside.ttm-fid-view-topicon h3:after,
.ttm-highlight-fid-style1,
.ttm-bgcolor-grey .ttm-pagination .page-numbers,
.featured-imagebox-portfolio .featured-iconbox.ttm-media-link a:hover,
.featured-imagebox-team .ttm-social-links-wrapper ul li a:hover,
.featured-imagebox:hover .ttm-box-bottom-content .ttm-btn.btn-inline:before,
.widget-download ul.download li,
.ttm-tabs.style2 ul.tabs li.active a,
.ttm-tabs.style2 ul.tabs li:hover a,
#payment .payment_box {
  background-color: #fff;
}

/** White-border-color **/
.ttm-bgcolor-skincolor .featured-icon-box .featured-icon.ttm-icon_element-border,
.ttm-bgcolor-darkgrey .featured-icon-box .featured-icon.ttm-icon_element-border,
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white,
.ttm-btn.ttm-btn-color-white.ttm-btn-style-border:hover,
.white-border .with-top-border,
.ttm-bgcolor-grey .ttm-pagination .page-numbers,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-cart-link a,
.ttm-header-style-overlay .ttm-header-icons .ttm-header-search-link a,
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input input[type='text']:focus,
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input textarea[type='text']:focus {
  border-color: #fff;
}
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input input[type='text']:focus,
.ttm-bgcolor-skincolor .wrap-form.ttm-contactform span.text-input textarea[type='text']:focus {
  border-color: #fff !important;
}
.ttm-bgcolor-skincolor .heading-seperator span:after,
.ttm-bgcolor-skincolor .heading-seperator:after {
  border-bottom-color: #fff !important;
}
.ttm-bgcolor-skincolor .separator .sep-line {
  border-color: rgba(255, 255, 255, 0.24);
}
.footer .second-footer {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
.ttm-highlight-fid-style1:after {
  border-top-color: rgba(255, 255, 255, 1);
}
/** 4.Grey-color **/
.ttm-bgcolor-grey > .ttm-bg-layer,
.ttm-bgcolor-white > .ttm-bg-layer > .ttm-bg-layer-inner,
.ttm-bgcolor-grey,
.ttm-btn-bgcolor-grey,
.ttm-header-style-classicinfo .ttm-content-wrapper,
.ttm-search-overlay .ttm-site-searchform input[type='search'],
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-color-grey,
.sidebar .ttm-bgcolor-grey > .ttm-bg-layer,
.featured-imagebox-team .featured-content-team,
.accordion.style2 .toggle-content,
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li a {
  background-color: #f5faff;
}
.grey-border .ttm_single_image-wrapper.with-border15 img {
  border-color: #e8f0f8;
}
/* Extra-color*/
.testimonials.style2 .ttm-ratting-star {
  color: #fd4;
}
.ttm-textcolor-dark,
.ttm-top-info-con .title-box p {
  color: #687076 !important;
}
.products .star-ratings,
.summary .star-rating,
div.product #reviews .star-rating,
.comment-form-rating .stars {
  color: #f5cc26 !important;
}
/* ===============================================
    Pre-loader
------------------------*/
#preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -30px 0 0 -30px;
}
#status {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background: #fff;
  background-image: url(../images/bascule/preloader-128x128.gif);
  background-repeat: no-repeat;
  background-position: center;
}

/*  ===============================================
    SocialIcon
------------------------*/
.social-icons ul {
  margin: 0;
  padding: 0;
}
.social-icons {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
.social-icons li {
  display: inline-block;
  border: none;
  margin: 0 3px;
}
.social-icons.circle li > a {
  border: 1px solid #e8ecef;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  display: block;
  font-size: 13px;
  border-radius: 50%;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  border: none;
  color: #2c2c2c;
}
.ttm-bgcolor-darkgrey .social-icons.circle li > a,
.ttm-bgcolor-skincolor .social-icons.circle li > a {
  background-color: #fff;
}

/*  ===============================================
    TooltipTop
------------------------*/
.tooltip-top {
  position: relative;
}
.tooltip:after,
.tooltip:before,
[data-tooltip]:after,
[data-tooltip]:before {
  position: absolute;
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: 0 0;
  content: '';
}
.tooltip-top:before,
.tooltip:before,
[data-tooltip]:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: rgba(24, 35, 51, 1);
}
.tooltip-top:after,
.tooltip-top:before,
.tooltip:after,
.tooltip:before,
[data-tooltip]:after,
[data-tooltip]:before {
  bottom: 78%;
  left: 50%;
}
.tooltip-top:focus:after,
.tooltip-top:focus:before,
.tooltip-top:hover:after,
.tooltip-top:hover:before,
.tooltip:focus:after,
.tooltip:focus:before,
.tooltip:hover:after,
.tooltip:hover:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  -webkit-transform: translateY(-16px);
  -moz-transform: translateY(-16px);
  transform: translateY(-16px);
}
.tooltip-top:after,
.tooltip:after,
[data-tooltip]:after {
  margin-left: -80px;
}
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: rgba(24, 35, 51, 1);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  border-radius: 5px;
}
.tooltip:focus:after,
.tooltip:focus:before,
.tooltip:hover:after,
.tooltip:hover:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(Opacity=100);
  opacity: 1;
}

/*  ===============================================
    TopBar
------------------------*/
.ttm-topbar-wrapper {
  line-height: 49px;
}
.ttm-topbar-wrapper .top-contact.text-left {
  position: relative;
  float: left;
  line-height: 50px;
}
.top-contact li {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.top-contact li:last-child {
  padding-right: 22px !important;
}
.top-contact li:first-child {
  padding-left: 0px !important;
}
.top-contact li strong {
  font-weight: 500;
}
.top-contact {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  display: inline-block;
  position: relative;
}
.ttm-header-style-infostack .topbar-right .top-contact {
  padding-left: 15px;
}
.topbar-right .top-contact li:before {
  content: '';
  height: 18px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.09);
  display: block;
  position: absolute;
  right: 0;
  top: 17px;
}
.topbar-right .top-contact.ttm-highlight-right li:last-child:after {
  content: unset;
}
.ttm-topbar-wrapper .ttm-social-links-wrapper {
  margin: 0;
  position: relative;
  padding-left: 13px;
}
.ttm-topbar-wrapper .social-icons li {
  margin: 0 3px;
}
.ttm-topbar-wrapper .social-icons li > a {
  display: block;
  border: none;
  width: 16px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
  text-align: center;
}
.ttm-topbar-content .topbar-right .list-inline {
  display: inline-block;
  line-height: 49px;
}
.top-contact.ttm-highlight-left {
  margin-right: 15px;
  padding-right: 5px;
}
.top-contact.ttm-highlight-right {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
}
.top-contact.ttm-highlight-left:after,
.top-contact.ttm-highlight-right:after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  width: 5000px;
  height: 100%;
}
.top-contact.ttm-highlight-right:after {
  right: 0;
}
.top-contact.ttm-highlight-right:after {
  left: 0;
}
.top-contact strong {
  font-weight: 600;
}
.top-contact i {
  margin-right: 9px;
}
.ttm-top-info-con ul li {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  height: 100%;
  margin: 0;
}
.ttm-top-info-con > ul {
  padding: 0;
  margin: 0;
  padding: 0;
  margin: 0;
  display: table;
  vertical-align: middle;
  height: 100%;
}
.ttm-top-info-con {
  float: right;
  position: relative;
  z-index: 3;
  text-align: right;
}
.ttm-top-info-con > ul:not(.social-icons) > li {
  padding: 0 20px;
}
.ttm-top-info-con > ul:not(.social-icons) > li:last-child {
  padding-right: 0;
}
.ttm-top-info-con > ul:not(.social-icons) > li:first-child {
  padding-left: 0;
}
.ttm-top-info-con ul li .icon-box,
.ttm-top-info-con ul li .title-box {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
ul li .icon-box.icon-style-border {
  position: relative;
}
ul li .icon-box.icon-style-border i {
  border: 1px solid;
  position: relative;
  display: block;
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
}
.icon-box i {
  font-size: 18px;
}
.ttm-top-info-con ul li .title-box {
  padding-left: 18px;
}
.ttm-top-info-con .title-box h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0;
}
.ttm-top-info-con .title-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 0;
}

.ttm-header-style-classicinfo .ttm-content-wrapper {
  position: relative;
  padding-top: 19px;
  padding-bottom: 16px;
}
.ttm-header-style-classicinfo .top-contact.ttm-highlight-right {
  position: relative;
  padding-left: 20px;
  float: right;
  line-height: 48px;
  bottom: -18px;
  font-size: 16px;
}
.ttm-header-style-classicinfo .ttm-top-info-con ul li:not(:last-child):after {
  content: unset;
}

/*  ===============================================
    Header
------------------------*/

/** SiteBrand(logo) **/
.site-branding {
  float: left;
  height: 140px;
  line-height: 99px;
  position: relative;
  display: block;
  z-index: 1;
}
.ttm-header-top-wrapper {
  position: relative;
}
.site-branding img {
  /*max-height: 48px;*/
  margin-top: 12px;
}
/** SiteNavigation(Menu) **/
.site-navigation {
  position: relative;
}
.ttm-header-style-infostack #site-header-menu .container {
  padding: 0;
}
#site-header-menu #site-navigation .menu ul li ul > li:not(:last-child) {
  border-bottom: 1px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.04);
}
#site-header-menu #site-navigation .menu ul {
  padding: 0px;
  margin: 0px;
}
.ttm-menu-toggle {
  float: right;
}
.ttm-menu-toggle input[type='checkbox'] {
  display: none;
}
#site-header-menu #site-navigation .menu > ul > li {
  display: inline-block;
  position: relative;
}
#site-header-menu #site-navigation .menu ul li:hover > ul {
  opacity: 1;
  display: block;
  visibility: visible;
  height: auto;
  transform: translate(0, 0);
}
#site-header-menu #site-navigation .menu ul ul > li {
  position: relative;
}
#site-header-menu #site-navigation .menu ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
}
#site-header-menu #site-navigation .menu ul li > a {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
}
#site-header-menu #site-navigation ul ul li {
  position: relative;
  border-bottom: 1px solid transparent;
}
#site-header-menu #site-navigation .menu ul li > ul li > a {
  margin: 0;
  display: block;
  padding: 15px 20px;
  position: relative;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  height: auto;
  line-height: 18px;
}
#site-header-menu #site-navigation .menu ul > li > ul li.has-submenu > a:after {
  content: '\f105';
  position: absolute;
  background-color: transparent;
  right: 12px;
  top: 16px;
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: 15px;
  margin-left: 4px;
  opacity: 0.8;
}
#site-header-menu #site-navigation .menu ul li a span {
  padding: 1px 3px 2px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 7px;
}

/** ttm-rt-contact(HeaderIcons - Search, Cart) **/
.ttm-header-icons {
  position: relative;
  padding-left: 29px;
}
.ttm-header-icons .ttm-header-cart-link a,
.ttm-header-icons .ttm-header-search-link a {
  position: relative;
  border: none;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  height: 31px;
  width: 31px;
  line-height: 31px;
  color: rgba(11, 12, 38, 1);
  border-color: rgba(11, 12, 38, 1);
  border: 1px solid;
  font-size: 12px !important;
  z-index: 9;
}
.ttm-header-icons .ttm-header-cart-link a i,
.ttm-header-icons .ttm-header-search-link a i {
  font-weight: 900;
}
.ttm-header-icons .ttm-header-cart-link {
  padding-left: 4px;
  padding-right: 0px;
}
.ttm-header-icons .ttm-header-icon {
  margin-left: 7px;
}
.ttm-header-icons .ttm-header-cart-link span.number-cart {
  position: absolute;
  bottom: 20px;
  left: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 9px;
  display: block;
  color: #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header-btn .ttm-btn.ttm-btn-size-md {
  font-size: 12px;
  padding: 8px 17px 8px 17px;
}
.header-btn {
  padding-left: 24px;
}
.header-btn,
.ttm-header-icons,
.ttm-header-icon {
  height: 100px;
  line-height: 100px;
  float: right;
}

/**  ttm-search-overlay **/
.ttm-header-icon {
  position: relative;
}
.ttm-search-overlay {
  position: absolute;
  top: 100%;
  right: 0px;
  width: 400px;
  background: #fff;
  border-top: 3px solid;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  z-index: 111;
}
.ttm-header-style-overlay .ttm-search-overlay .w-search-form-row:before,
.ttm-header-style-classic .ttm-search-overlay .w-search-form-row:before {
  right: 7px;
}
.ttm-search-overlay .w-search-form-h {
  display: table;
  height: 100%;
  width: 100%;
}
.ttm-search-overlay .w-search-form-row {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  position: relative;
  padding: 20px;
}
.ttm-search-overlay .w-search-form-row:before {
  position: absolute;
  right: 17px;
  top: -8px;
  width: 47px;
  height: 47px;
  text-align: center;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid;
  content: ' ';
}
.ttm-search-overlay .ttm-site-searchform input[type='search'] {
  font-size: 14px;
  text-align: left;
  height: 50px;
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none !important;
  color: #333;
  width: 85%;
  line-height: 1.3em;
  float: left;
}
.ttm-search-overlay .ttm-site-searchform button {
  font-size: 21px;
  width: 15%;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: 50px;
  text-align: center;
  display: block;
  line-height: 50px;
  color: #fff;
  float: left;
}
.ttm-header-search-link .ttm-tectxon-icon-search:before {
  font-weight: 600;
}
.ttm-tectxon-icon-search:before {
  content: '\e825';
  font-family: 'themify';
}
.ttm-search-overlay.st-show {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* ===============================================
    Footer
------------------------*/
.footer {
  /* background-image: url(../images/bascule/footer_bottom.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;*/
  background-color: #0f112a;
}
.footer .widget {
  margin: 60px 0 55px;
}
.footer .ttm-textcolor-white {
  color: rgba(255, 255, 255);
}
.footer .widget .ttm-btn.ttm-btn-size-md {
  padding: 14px 27px 14px 27px;
}

/** FirstFooter **/
.first-footer {
  /* background-image: url(../images/bascule/footer1.jpg);
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;*/
  background-color: #76777b;
}
.first-footer {
  padding: 62px 0 55px;
}
.footer-logo {
  margin-bottom: 45px;
}
.footer-logo img {
  max-height: 75px;
  width: auto;
}
.first-footer .footer-box {
  border-top: 0px solid #e5eef7;
  border-bottom: 0px solid #e5eef7;
}
.first-footer .widget-area {
  padding: 33px 15px 37px;
  position: relative;
}
.first-footer .featured-title h5,
.first-footer .featured-desc p {
  margin-bottom: 0;
}
/* newsletter-form */
.newsletter-form {
  position: relative;
  margin-bottom: 10px;
  margin-top: 48px;
}
.newsletter-form input[type='email'] {
  background-color: #fff;
  width: 100%;
  height: 47px;
  font-size: 14px;
  outline: none;
  border: none;
  border-radius: 25px;
  padding-left: 50px;
  -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.04);
}
.newsletter-form input[type='submit'] {
  height: 47px;
  width: 115px;
  border: 0;
  outline: none;
  z-index: 21;
  padding: 0;
  right: 0;
  border-radius: 0 50px 50px 0;
  box-shadow: 0 0;
  font-weight: 600;
  font-size: 12px;
  padding-left: 0;
  padding-right: 20px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.newsletter-form i {
  padding-right: 1px;
  position: absolute;
  left: 25px;
  top: 16px;
}
.mailchimp-inputbox:after {
  position: absolute;
  content: '\e628';
  top: 11px;
  right: 26px;
  font-family: 'themify';
  font-style: normal;
  font-weight: 400;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  font-size: 14px;
  color: #fff;
  z-index: 99;
}
/** SecondFooter **/
.second-footer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}
.widget ul {
  margin: 0;
  padding: 0;
}
.widget ul > li {
  padding: 0 0 7px 0;
  position: relative;
}
.widget .widget-title {
  position: relative;
  margin-bottom: 35px;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
}
.widget .widget-title:after {
  display: block;
  height: 3px;
  margin-top: 0;
  margin-bottom: -13px;
  content: '';
  position: absolute;
  bottom: 0;
  width: 20px;
}
/** footer-social-icons **/
.footer .social-icons li {
  margin-left: 3px;
  margin-right: 3px;
  min-width: auto;
}
.footer .social-icons li:first-child {
  margin-left: 0;
}
.footer .social-icons li > a {
  background-color: #212239;
  border: none;
  font-size: 15px;
}

/** timelist-block **/
.footer .widget ul.ttm-timelist-block {
  padding: 0;
  font-size: 14px;
  margin-top: 0;
}
.footer .widget ul.ttm-timelist-block li:first-child {
  padding-top: 0;
}
.footer .widget ul.ttm-timelist-block li {
  font-size: 15px;
  line-height: 23px;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
.footer .widget ul.ttm-timelist-block li .service-time {
  float: right;
}

/** menu-footer-services **/
.widget ul#menu-footer-services li a {
  padding-left: 16px;
  position: relative;
}
/*.widget ul#menu-footer-services li a:before {
  font-family: 'FontAwesome';
  content: '\f101';
  position: absolute;
  top: 0px;
  left: 0;
  line-height: 18px;
  font-size: 15px;
}*/
/* our-location-widget */
ul.ttm-our-location-list li {
  padding: 0 0 4px 0;
}
ul.ttm-our-location-list li i {
  margin-right: 7px;
  font-size: 15px;
}
/** Footer-nav-menu **/
.copyright {
  text-align: center;
  padding-top: 23px;
  padding-bottom: 23px;
}

/** Footer-nav-menu **/
.footer-nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-nav-menu li {
  display: inline-block;
  padding-left: 2px;
  padding-right: 16px;
  font-size: 14px;
  padding-bottom: 0;
}
.footer-nav-menu li:last-child {
  padding-right: 0;
}
.footer-nav-menu li:first-child {
  padding-left: 0;
}
ul.footer-nav-menu li:not(:last-child):after {
  position: absolute;
  content: '|';
  padding-left: 7px;
}

/* ===============================================
    GoTop BUtton
------------------------*/
#totop {
  display: none;
  cursor: pointer;
  font-weight: 900;
  color: #fff;
  position: fixed;
  right: 34px;
  bottom: 34px;
  z-index: 999;
  height: 0;
  width: 0;
  font-size: 0;
  text-align: center;
  padding-top: 3px;
  line-height: 34px;
  border-radius: 3px;
}
#totop.top-visible {
  height: 40px;
  width: 40px;
  font-size: 22px;
  display: inline;
}

/* ===============================================
    Page-Title-Row
------------------------*/

.ttm-page-title-row {
  position: relative;
  background-image: url(https://via.placeholder.com/1920X380/666666.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
div.ttm-page-title-row > .ttm-bg-layer {
  background-color: rgba(24, 35, 51, 0.85);
}
.ttm-page-title-row {
  display: block;
  padding-top: 100px;
  padding-bottom: 85px;
  z-index: 11;
}

/*div.ttm-page-title-row-bascule > .ttm-bg-layer { background-color: rgba(24,35,51,0.85); }*/

.ttm-page-title-row-bascule {
  display: block;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  z-index: 11;
  margin-top: 150px;
  background: #fbfbfb;
}

ttm-page-title-row-bascule-mob {
  display: block;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  z-index: 11;
  margin-top: 0px;
  background: #fbfbfb;
}

.title-box .page-title-heading h1 {
  text-transform: capitalize;
  font-size: 43px;
  line-height: 50px;
  margin-bottom: 10px;
}
.title-box .page-title-heading {
  position: relative;
}
breadcrumb-wrapper a {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

/* ===============================================
    Inner-Pages
------------------------*/

/*------------------------------------------------------------------------------*/
/*  Contact_Us
/*------------------------------------------------------------------------------*/

#map_canvas {
  margin: 0;
  padding: 0;
  height: 100%;
}
.map-wrapper {
  height: 630px;
  padding: 10px 0 0 0;
  margin-bottom: 08px;
}
.map-wrapper.style2 {
  height: 100%;
  margin: 0;
  padding: 0;
}

ul.ttm_contact_widget_wrapper {
  padding: 0;
}
ul.ttm_contact_widget_wrapper li {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 25px;
  border-bottom: 1px solid #eaeaea;
}
ul.ttm_contact_widget_wrapper li h6 {
  font-size: 18px;
  line-height: 24px;
  padding-left: 37px;
}
ul.ttm_contact_widget_wrapper li:last-child {
  border: 0;
}
ul.ttm_contact_widget_wrapper li i {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 20px;
  font-weight: 400;
}
.ttm-rounded-shadow-box {
  border-radius: 10px;
  border-top: 7px solid;
}

/*------------------------------------------------------------------------------*/
/*  Error_page
/*------------------------------------------------------------------------------*/
.error-404 {
  position: relative;
}
.page-header {
  margin: 40px 0 20px;
}
section.error-404 {
  padding: 150px 0;
  text-align: center;
  background-image: url(https://via.placeholder.com/1920X629/666666.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
section.error-404 .ttm-big-icon {
  font-size: 150px;
}
section.error-404 h1 {
  font-size: 95px;
  text-transform: uppercase;
  line-height: 100%;
}
section.error-404 .page-content p {
  font-size: 18px;
  margin-bottom: 30px;
}

/*------------------------------------------------------------------------------*/
/*  Portfolio
/*------------------------------------------------------------------------------*/
.ttm-social-share-title h5 {
  font-size: 18px;
  font-weight: 500;
  padding-right: 10px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 0;
}
.ttm-nextprev-bottom-nav .nav-next,
.ttm-nextprev-bottom-nav .nav-previous {
  display: inline-block;
  position: relative;
}
.ttm-nextprev-bottom-nav .nav-next a,
.ttm-nextprev-bottom-nav .nav-previous a {
  text-align: center;
  top: 106px;
  right: 48px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  border-radius: 0;
  border-width: 0;
  border-style: solid;
  font-size: 15px;
  padding: 12px 34px;
  border-radius: 0;
  font-weight: 500;
}
.ttm-nextprev-bottom-nav .nav-previous a {
  padding-right: 25px;
  padding-left: 44px;
}
.ttm-nextprev-bottom-nav .nav-previous a:after {
  content: '\f100';
  font-family: 'FontAwesome';
  left: 25px;
}
.ttm-nextprev-bottom-nav .nav-previous a:after,
.ttm-nextprev-bottom-nav .nav-next a:after {
  position: absolute;
  font-size: 16px;
  top: 13px;
}
.ttm-pf-single-related-title {
  font-size: 28px;
}

/*------------------------------------------------------------------------------*/
/*  blog
/*------------------------------------------------------------------------------*/
article.ttm-blog-classic {
  margin-bottom: 30px;
  padding-bottom: 0;
}
article.ttm-blog-classic,
.post-featured-wrapper {
  position: relative;
}
.ttm-blog-classic .featured-imagebox-post {
  margin-top: 0;
  margin-bottom: 0;
}
.ttm-blog-classic .featured-imagebox-post .featured-content.featured-content-post {
  padding: 20px 0 20px;
}
.ttm-blog-classic .featured-imagebox-post:hover {
  box-shadow: none;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.ttm-blog-classic .featured-imagebox-post .featured-content.featured-content-post .post-title h5 {
  font-size: 30px;
  line-height: 39px;
}
.ttm-blog-classic .featured-imagebox-post .featured-content.featured-content-post .post-meta span {
  padding-right: 0;
}
.ttm-blog-classic .featured-imagebox-post .post-meta span:not(:last-child):after {
  background-color: #eaeaea;
  display: inline-block;
  content: '';
  width: 1px;
  height: 20px;
  vertical-align: middle;
  margin: 0 15px;
}
.ttm-blog-classic .ttm-blog-classic-content blockquote {
  display: block;
  background: #f5faff;
  padding: 25px 20px 20px 85px;
  position: relative;
  font-weight: 300;
  margin: 35px 0 35px 0;
  font-style: normal;
  font-size: 17px;
  min-height: 100px;
  line-height: 28px;
  font-style: italic;
  color: #687076;
  border-left: 4px solid;
}
.ttm-blog-classic .ttm-blog-classic-content blockquote:before {
  content: '"';
  display: inline-block;
  position: absolute;
  font-size: 110px;
  font-family: 'Myriad Pro';
  transform: rotate(180deg);
  top: 0;
  left: 33px;
}
.ttm-tag-list span a {
  padding: 6px 15px;
  background-color: #f5faff;
  font-size: 14px;
  display: inline-block;
  margin-right: 3px;
}
/*blog-comment*/
.comments-area {
  margin-top: 50px;
  margin-bottom: 0;
}
.comments-area h2 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.comment-list li {
  position: relative;
  margin-top: 30px;
}
.comment-list .children {
  margin-left: 35px;
}
.comment-list,
.comment-list .children {
  list-style-type: none;
  padding: 0;
}
.single-post .comments-area {
  margin-top: 50px;
  margin-bottom: 0;
}
.single-post .comments-area .comments-title {
  font-size: 33px;
  font-weight: 600;
}
.comment-body {
  margin-left: 105px;
  margin-top: 35px;
  margin-bottom: 30px;
  padding: 20px 20px;
  position: relative;
  background-color: #f5faff;
  border: 1px solid #f5faff;
}
.comment-body:after,
.comment-body:before {
  position: absolute;
  top: 17px;
  width: 0;
  height: 0;
  border-width: 10px 12px 10px 0;
  border-style: solid;
  border-color: transparent #f5faff transparent #f5faff;
  z-index: 1;
  content: '';
  left: -12px;
}
.comment-author {
  float: left;
  text-align: center;
  width: 87px;
  height: 87px;
  display: block;
  margin-right: 25px;
  overflow: hidden;
  margin-left: -125px;
  margin-top: -20px;
}
.comment-author img {
  max-width: 100%;
  height: auto;
}
.comment-reply-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.comment-meta {
  font-size: 14px;
  padding: 0;
  position: relative;
}
.comment-meta,
.comment-meta a {
  color: #6e6e6e;
  font-size: 13px;
}
.comment-meta .ttm-comment-owner h5,
.ttm-comment-owner {
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #182333;
  font-style: normal;
}
.comment-box .author-content-wrap p {
  margin-top: 10px;
}
.single article.post .comment-body p {
  margin-top: 10px;
}
.comment-list a.comment-reply-link {
  border: none;
  color: #fff;
  font-size: 13px;
  padding: 2px 30px 3px 17px;
  position: relative;
  margin-top: 5px;
  display: inline-block;
}
.comment-list a.comment-reply-link:after {
  position: absolute;
  top: 6px;
  font-family: 'themify';
  content: '\e649';
  font-weight: 400;
  display: inline-block;
  margin-right: 0.2em;
  text-align: center;
  opacity: 1;
  line-height: 22px;
  font-size: 10px;
  margin-left: 8px;
}
.comments-area .comment-respond {
  padding-top: 10px;
}
#respond .comment-reply-title {
  margin: 0 0 20px !important;
}
.comment-form {
  margin-bottom: 20px;
}
.comment-form .comment-notes {
  margin-bottom: 25px;
  font-style: italic;
}
.comment-form p:not(.comment-notes) {
  margin-bottom: 20px;
}
.comment-form textarea {
  height: 125px;
}

.comment-form textarea,
.comment-form input[type='text'],
.comment-form input[type='email'],
.comment-form input[type='url'] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding-left: 0;
}
.comments-area .comment-form .comment-form-author,
.comments-area .comment-form .comment-form-email,
.comments-area .comment-form .comment-form-url {
  position: relative;
  float: left;
  width: 100%;
}
.comments-area .comment-form .comment-form-url {
  margin-right: 0;
}

/*------------------------------------------------------------------------------*/
/* team-member-single
/*------------------------------------------------------------------------------*/
.ttm-team-member-data ul {
  margin: 0;
  padding: 0;
  clear: both;
}
.ttm-team-details-list li {
  position: relative;
  padding: 9px 0 8px 0;
  font-size: 15px;
  line-height: 24px;
  display: block;
  float: left;
  width: 100%;
}
.ttm-team-details-list li i {
  margin-right: 8px;
}
.ttm-team-details-list .ttm-team-list-title,
.ttm-team-details-list .ttm-team-list-value {
  display: inline-block;
}
.ttm-team-details-list .ttm-team-list-title {
  margin-right: 3px;
  position: relative;
  font-size: 15px;
  line-height: 20px;
}
.ttm-team-member-data {
  padding: 35px 5px 35px 26px;
  background-color: #f5faff;
  margin-bottom: 30px;
}
.ttm-team-member-data ul.ttm-team-details-list {
  padding-left: 0;
}
.ttm-team-details-list .ttm-team-list-value a {
  color: #8d9297;
}
.ttm-team-member-single-title {
  font-size: 23px;
  line-height: 26px;
  margin-bottom: 0;
}
.ttm-team-member-single-position {
  font-size: 12px;
  line-height: 26px;
  color: #8d9297;
  font-weight: 400;
  margin-bottom: 10px;
}
.ttm-team-member-content blockquote {
  background-color: #f5faff;
  padding: 20px 25px;
  color: #687076;
  font-size: 16px;
  font-style: italic;
  margin-top: 20px;
}
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal {
  display: block !important;
}
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li {
  margin-top: 8px;
  margin-bottom: 7px;
  margin-left: 8px;
  margin-right: 7px;
}
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li:last-child {
  margin-right: 0;
}
.ttm-team-member-content .ttm-tabs.ttm-tab-style-horizontal ul.tabs li a {
  text-transform: capitalize;
  text-align: center;
  border-radius: 0;
  font-size: 17px;
  font-weight: 600;
}
.ttm-team-member-award-box {
  padding-left: 15px;
  padding-bottom: 40px;
  position: relative;
}
.ttm-team-member-award-box:before {
  content: '';
  position: absolute;
  left: 15px;
  top: -30px;
  width: 1px;
  height: 100%;
  background: #e8ecef;
}
.ttm-team-member-award-box h5 {
  margin-bottom: 5px;
  font-weight: 700;
  position: relative;
}
.ttm-team-member-award-box h5:before {
  content: '';
  position: absolute;
  border-radius: 0;
  left: 0;
  top: 5px;
  width: 12px;
  height: 12px;
  background-color: #e8ecef;
}
.ttm-team-member-award-box h5,
.ttm-team-member-award-box p {
  padding-left: 35px;
}

/*------------------------------------------------------------------------------*/
/*  Elements_page
/*------------------------------------------------------------------------------*/
.element-row {
  padding: 0 0 45px;
  position: relative;
}
.element-row .section-title h2.title {
  margin-top: 22px;
  margin-bottom: 25px;
}

/** element-progressbar **/
.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_blue {
  background-color: #0074cc;
}
.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_green {
  background-color: #5bb75b;
}
.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_pink {
  background-color: #f4524d;
}
.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_turquoise {
  background-color: #00c1cf;
}

/** icon_element-style-fill **/
.ttm-icon.ttm-icon_element-fill {
  color: #fff;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-yellow {
  background-color: #ffb300;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-orange {
  background-color: #ff4c00;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-red {
  background-color: #ff0a26;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-dark_pink {
  background-color: #ff0061;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-mulled_wine {
  background-color: #50485b;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-background-aqua {
  background-color: #39c8df;
}
.ttm-icon.ttm-icon_element-fill.ttm-icon_element-color-aqua {
  color: #39c8df;
}

/** icon_element-style-border **/
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-mulled_wine {
  border-color: #50485b;
  color: #50485b;
}
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-blue {
  border-color: #5472d2;
  color: #5472d2;
}
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-white {
  border-color: #fff;
  color: #fff;
}
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-yellow {
  border-color: #ffb300;
  color: #ffb300;
}
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-orange {
  border-color: #ff4c00;
  color: #ff4c00;
}
.ttm-icon.ttm-icon_element-border.ttm-icon_element-color-red {
  border-color: #ff0a26;
  color: #ff0a26;
}

/** icon_element-bgcolor **/
.element-style .icon-box-wrapper {
  padding-top: 15px;
}
.element-style .row .col-md-6 .featured-icon-box:not(:last-child) {
  padding: 0;
  margin-bottom: 40px;
}

/* ===   link-list ==
------------------------*/
.list-style-disc {
  list-style-type: disc !important;
}
.list-style-decimal {
  list-style-type: decimal !important;
}
ul.ttm-list {
  list-style: none;
  padding: 0;
  padding-left: 0;
}
.ttm-list.ttm-list-style-icon li i {
  position: absolute;
  left: 0px;
  top: 5px;
}
.ttm-list.ttm-list-style-icon li {
  padding-left: 27px;
  list-style: none;
  position: relative;
  padding-bottom: 8px;
}
.ttm-list.ttm-list-textsize-medium li {
  font-size: 16px;
  padding-bottom: 9px;
}
